<script lang="ts">
  import { onMount } from 'svelte';
  import { trackEvent } from '../util/snowplow';
  import { loginModelStore } from '../login/loginModel';

  onMount(() => {
    trackEvent({ action: 'state:login' });
  });

  const onClick = () => {
    $loginModelStore?.login();
  };
</script>

<p>
  For å sikre en rettferdig og trygg avstemning, må du logge på for å gi din
  stemme.
</p>

<button
  on:click={onClick}
  class="primary login-button"
  data-test-id="login-button"
>
  <span class="button-text-gradient" aria-hidden="true">Logg på</span>
  Logg på</button
>

<style>
  .login-button {
    box-shadow: 0 0 0 0 rgba(245, 45, 255, 1);
    transform: scale(0.95);
    animation: pulse 1.5s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(245, 45, 255, 0.8);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 10px 20px rgba(0, 0, 0, 0);
    }
  }
  p {
    margin-bottom: var(--spacing-16);
    text-align: center;
    max-width: 25rem;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 950;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
</style>
