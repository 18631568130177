export const disableHoverOnTouch = () => {
  if (!hasTouch()) {
    const bodyRef = document.getElementsByTagName('body')[0];
    bodyRef.classList.add('no-touch');
  }
};

function hasTouch() {
  return (
    'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0
  );
}
