// prettier-ignore
export const starVertices = 
[
-0.000000, -0.000000, -0.001108, 0.0, -1.0, 0.0,
-0.001731, -0.000000, -0.002382, 0.0, -1.0, 0.0,
-0.001054, -0.000000, -0.000342, 0.0, -1.0, 0.0,
-0.002800, -0.000000, 0.000910, 0.0, -1.0, 0.0,
-0.000651, -0.000000, 0.000896, 0.0, -1.0, 0.0,
0.000000, -0.000000, 0.002944, 0.0, -1.0, 0.0,
0.000651, -0.000000, 0.000896, 0.0, -1.0, 0.0,
0.002800, -0.000000, 0.000910, 0.0, -1.0, 0.0,
0.001054, -0.000000, -0.000342, 0.0, -1.0, 0.0,
0.001731, -0.000000, -0.002382, 0.0, -1.0, 0.0,
];

// prettier-ignore
const starIndiciesObjFormat =  [
 3, 2,1,
 1, 10,9,
 3, 1,9,
 3, 9,7,
 5, 3,7,
 7, 9,8,
 6, 5,7,
 5, 4,3,
];
// Blender export .obj file is 1-indexed, so we have to subtract 1
export const starIndicies = starIndiciesObjFormat.map((i) => i - 1);
