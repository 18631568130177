<script lang="ts">
  export let text: string;
  export let name: string;
  export let group: number;
  export let value: number;
  export let selected: boolean;
</script>

<label>
  <input class="sr-only" type="radio" bind:group {value} {name} />
  <div class="emoji-container" class:selected>
    <slot />
  </div>
  <div class="emojitext">{text}</div>
</label>

<style>
  label {
    width: 100%;
    display: block;
    height: 100%;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.43656rem; /* 176.808% */
  }

  label:hover {
    cursor: pointer;
  }
  .emojitext {
    margin-top: var(--spacing-4);
  }

  .emoji-container {
    padding: var(--spacing-8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selected {
    border-radius: 4px;
    background-color: var(--color-feedback-checked-emoji-bg);
  }
</style>
