import { glMatrix, mat4, vec3 } from 'gl-matrix';
import particleFragmentShader from './particle-fragment-shader.glsl?raw';
import particleVertexShader from './particle-vertex-shader.glsl?raw';
import { starIndicies, starVertices } from './starshape.js';
import { Shader, VertexBuffer, mglResizeCanvas } from './webgl-utils.js';
const numParticles = 500;
const near = -0.075;
const far = -0.15;
const bottom = -0.06;
let particles: Particle[] = [];
let runAnimation = false;
let aspectRatio: number;
let prevTime = 0;
let elapsedTime = 0;

type Particle = {
  matrix: mat4;
  xpos: number;
  ypos: number;
  zpos: number;
  tspeed: number;
  rxspeed: number;
  ryspeed: number;
  rzspeed: number;
  rxOffset: number;
  ryOffset: number;
  rzOffset: number;
  gravity: number;
  time: number;
  isLive: boolean;
};

function createParticle(): Particle {
  const width = window.innerWidth * 0.00012;

  const z = near - Math.random() * Math.abs(far - near);
  const x = Math.random() * width - width / 2.0;
  const tspeed = (0.03 + Math.random() * 0.04) * 0.015;
  return {
    matrix: mat4.create(),
    xpos: x, //Math.random() * width - width / 2.0,
    ypos: bottom,
    zpos: z, //near - Math.random() * Math.abs(far - near),
    tspeed,
    rxspeed: (Math.random() * 2.0 - 1) * 0.01,
    ryspeed: Math.random() * 2.0 - 1 * 0.1,
    rzspeed: Math.random() * 2.0 - 1 * 0.5,
    rxOffset: (Math.random() * 2.0 - 1.0) * 0.0025,
    ryOffset: (Math.random() * 2.0 - 1.0) * 0.0025,
    rzOffset: (Math.random() * 2.0 - 1.0) * 0.0025,
    gravity: (0.02 + Math.random() * 0.11) * 1.1,
    time: tspeed * 1.0,
    isLive: true,
  };
}

function deleteDeadParticles(elapsedTime: number) {
  particles = particles.filter((particle) => particle.isLive);

  // If framerate is low and there are a lot of particles,
  // we can kill some of them for the next frame.
  if (elapsedTime > 20 && particles.length > 100) {
    particles.splice(0, 20);
  }
}

function updateParticle(particle: Particle) {
  if (
    particle.ypos <= bottom + 0.04 &&
    particle.time > particle.tspeed * (1.0 + elapsedTime)
  ) {
    particle.isLive = false;
    return;
  }
  particle.time += particle.tspeed * (1.0 + elapsedTime);
  particle.ypos = Math.sin(particle.time) * particle.gravity;

  particle.matrix = mat4.create();

  mat4.translate(
    particle.matrix,
    particle.matrix,
    vec3.fromValues(particle.xpos, bottom + particle.ypos, particle.zpos)
  );

  mat4.rotate(
    particle.matrix,
    particle.matrix,
    particle.time * 5,
    vec3.fromValues(particle.rxspeed, 0, 0)
  );

  mat4.rotate(
    particle.matrix,
    particle.matrix,
    particle.time * 5,
    vec3.fromValues(0, particle.ryspeed, 0)
  );

  mat4.rotate(
    particle.matrix,
    particle.matrix,
    particle.time * 5,
    vec3.fromValues(0, 0, particle.rzspeed)
  );

  mat4.translate(
    particle.matrix,
    particle.matrix,
    vec3.fromValues(particle.rxOffset, particle.ryOffset, particle.rzOffset)
  );
}

export const initWebGl = (
  glContext: WebGLRenderingContext & { canvas: HTMLCanvasElement }
) => {
  mglResizeCanvas(glContext);
  const particleShader = new Shader(
    particleVertexShader,
    particleFragmentShader,
    glContext
  );

  // Create particle vertex buffer
  const particleVbo = new VertexBuffer(
    glContext.ARRAY_BUFFER,
    glContext.STATIC_DRAW,
    glContext
  );
  particleVbo.bind(particleShader);
  particleVbo.setBufferData(starVertices);
  particleVbo.mapAttribute('a_position', 3);
  particleVbo.mapAttribute('a_normal', 3);

  const particleIndexVbo = new VertexBuffer(
    glContext.ELEMENT_ARRAY_BUFFER,
    glContext.STATIC_DRAW,
    glContext
  );
  particleIndexVbo.bind(particleShader);
  particleIndexVbo.setBufferData(starIndicies);

  const projection = mat4.create();
  const normalMatrix = mat4.create();
  aspectRatio = glContext.canvas.clientWidth / glContext.canvas.clientHeight;

  mat4.perspective(
    projection,
    glMatrix.toRadian(45.0),
    aspectRatio,
    0.000001,
    80.0
  );

  glContext.enable(glContext.DEPTH_TEST);
  glContext.clearColor(0.0, 0.0, 0.0, 0.0);

  function draw(timestamp: number) {
    if (runAnimation) {
      mglResizeCanvas(glContext);

      glContext.clear(glContext.COLOR_BUFFER_BIT | glContext.DEPTH_BUFFER_BIT);
      glContext.blendFunc(glContext.SRC_ALPHA, glContext.ONE);
      glContext.enable(glContext.BLEND);
      glContext.disable(glContext.DEPTH_TEST);

      particleVbo.bind(particleShader);
      particleShader.use();

      const newAspect =
        glContext.canvas.clientWidth / glContext.canvas.clientHeight;
      if (newAspect !== aspectRatio) {
        aspectRatio = newAspect;
        mat4.perspective(
          projection,
          glMatrix.toRadian(45.0),
          aspectRatio,
          0.000001,
          1800.0
        );
      }
      particleShader.setUniformMat4('projection', projection);

      for (let i = 0; i < particles.length; i++) {
        mat4.invert(normalMatrix, particles[i].matrix);
        mat4.transpose(normalMatrix, normalMatrix);
        particleShader.setUniformMat4('normalMatrix', normalMatrix);
        particleShader.setUniformMat4('model', particles[i].matrix);
        particleIndexVbo.drawTriangles();
        updateParticle(particles[i]);
      }

      if (prevTime > 0) {
        elapsedTime = timestamp - prevTime;
      }
      prevTime = timestamp;

      deleteDeadParticles(elapsedTime);

      // If we have particles left to animate, lets continue running, if not
      // we should set runAnimation to false to allow a new animation to trigger later on.
      if (particles.length > 0) {
        window.requestAnimationFrame(draw);
      } else {
        runAnimation = false;
        glContext.clear(
          glContext.COLOR_BUFFER_BIT | glContext.DEPTH_BUFFER_BIT
        );
      }
    }
  }

  return {
    stopConfetti: () => {
      // kill drawloop
      runAnimation = false;
      // We can probably rely on garbage collection if canvas and references are removed
      // but we can also go down the gl.delete* route, probably need to fix webgl-utils a bit.
      // gl.deleteBuffer?
      // gl.deleteRenderBuffer?
    },
    //TODO: pause / play on leaving the window?
    startConfetti: (numberOfParticles = numParticles) => {
      for (let i = 0; i < numberOfParticles; i++) {
        particles.push(createParticle());
      }
      // console.log(particles.length, runAnimation);
      // If there is already an draw-loop running we do not want to start another.
      if (runAnimation === false) {
        runAnimation = true;
        elapsedTime = 0;
        prevTime = 0;
        draw(0);
      }
    },
  };
};
