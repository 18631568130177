<script lang="ts">
  import type { BeforeState } from '@pin/schemas/client-state-response';
  import { onMount } from 'svelte';
  import AlternativeList from '../components/AlternativeList.svelte';
  import AlternativeItem from '../components/AlternativeItem.svelte';
  import { trackEvent } from '../util/snowplow';

  export let pollData: BeforeState;

  onMount(() => {
    trackEvent(
      { action: 'state:before-poll', label: pollData.title },
      pollData.id
    );
  });

  $: teamOneAlternatives = pollData.alternatives.filter(
    (a) => a.smsId === 'lag-1'
  );
  $: teamTwoAlternatives = pollData.alternatives.filter(
    (a) => a.smsId !== 'lag-1'
  );
</script>

<!-- svelte-ignore a11y-autofocus -->
<h2 autofocus tabindex="-1">Avstemningen har ikke åpnet enda</h2>
<h3>Lag 1</h3>
<AlternativeList>
  {#each teamOneAlternatives as alternative, i (alternative.id)}
    <AlternativeItem imagePos={i % 2 === 0 ? 'left' : 'right'} {alternative} />
  {/each}
</AlternativeList>
<h3>Lag 2</h3>
<AlternativeList>
  {#each teamTwoAlternatives as alternative, i (alternative.id)}
    <AlternativeItem imagePos={i % 2 === 0 ? 'left' : 'right'} {alternative} />
  {/each}
</AlternativeList>

<style>
  h3 {
    margin-top: 1rem;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 500;
    font-size: 1.5rem;
  }
</style>
