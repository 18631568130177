<script lang="ts">
  import { nrkClose } from '@nrk/core-icons';
  import { postFeedback } from '../../api/feedback';
  import loginIssuesStrings from '../../util/loginIssuesStrings';
  import { trackEvent } from '../../util/snowplow';
  import FocusText from '../FocusText.svelte';
  import CoreDialog from '../core/CoreDialog.svelte';
  import FeedbackForm from './FeedbackForm.svelte';

  let submitting = false;
  let noIssues = false;
  let email = '';
  let explanation = '';

  const onClickSend = async () => {
    if (
      loginIssuesStrings.some((s) => {
        return comment.toLowerCase().includes(s);
      }) &&
      !noIssues &&
      state !== 'login-issues'
    ) {
      state = 'login-issues';
      return;
    }
    submitting = true;
    if (email !== '' && explanation !== '') {
      comment = comment + '\nForklaring:\n' + explanation;
    }

    try {
      await postFeedback(
        {
          comment,
          votesFeedback: emoji,
          email,
          category: email ? 'pålogging' : undefined,
        },
        { fid }
      );
      trackEvent({ action: 'click:feedback-submit' });
    } catch {
      trackEvent({ action: 'error:feedback-submit' });
    } finally {
      submitting = false;

      comment = '';
      emoji = -1;
      state = 'sent';
      fid = null;
    }
  };

  const onClickCancel = () => {
    trackEvent({ action: 'click:feedback-close' });
    feedbackDialog.close();
  };

  export let feedbackDialog: CoreDialog;

  let state: 'closed' | 'open' | 'sent' | 'login-issues' = 'closed';

  let comment = '';
  let emoji = -1;
  let fid: string | null = null;

  let emojiTimeout: number | undefined;
  const debounceEmoji = (newEmoji: number) => {
    if (emojiTimeout) {
      clearTimeout(emojiTimeout);
    }
    if (emoji !== -1) {
      emojiTimeout = window.setTimeout(async () => {
        const response = await postFeedback(
          { votesFeedback: newEmoji },
          { fid }
        );
        if (response) {
          fid = response.fid;
        }
      }, 5000);
    }
  };

  const toggleGlobalScroll = (currentState: typeof state) => {
    if (currentState !== 'closed') {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  $: sendDisabled =
    state === 'login-issues'
      ? email === ''
      : (comment === '' && emoji === -1) || submitting;
  $: debounceEmoji(emoji);
  $: toggleGlobalScroll(state);
</script>

<CoreDialog
  id="feedback-dialog"
  bind:this={feedbackDialog}
  label="Gi oss din tilbakemelding"
  on:open={() => {
    state = 'open';
  }}
  on:close={() => {
    state = 'closed';
  }}
>
  <div class="content">
    {#if state === 'open'}
      <h1 class="sr-only">Gi oss din tilbakemelding</h1>
      <FeedbackForm bind:group={emoji} bind:comment onSubmit={onClickSend}>
        <div class="action-container">
          <button class="back" on:click={onClickCancel} type="button"
            >Avbryt
          </button>
          <button
            class="send secondary"
            disabled={sendDisabled}
            aria-busy={submitting}
            ><span class="button-text-gradient" aria-hidden="true">Send</span>
            Send
          </button>
        </div>
      </FeedbackForm>
    {:else if state === 'login-issues'}
      <FeedbackForm bind:group={emoji} bind:comment onSubmit={onClickSend}>
        <FocusText elementType="h2" styling="margin: var(--spacing-32) 0;">
          <span>Har du problemer med å logge på? </span>
        </FocusText>
        <div class="login-issues-container">
          <label class="form-label">
            <span class="label-text"> Beskriv hva som er problemet: </span>
            <textarea
              class="explanation"
              placeholder="Forklar problemet med påloggingen"
              bind:value={explanation}
            ></textarea>
          </label>
          <p>Fyll inn e-posten din slik at vi kan se nærmere på problemet</p>
          <label class="form-label">
            <span class="label-text"> E-post: </span>
            <input
              class="email"
              type="email"
              placeholder="Din e-post"
              bind:value={email}
            />
          </label>
          <button
            class="send secondary"
            disabled={sendDisabled}
            aria-busy={submitting}
            ><span class="button-text-gradient" aria-hidden="true">Send</span>
            Send
          </button>
          <button
            class="back-button"
            on:click={() => {
              state = 'open';
              noIssues = true;
            }}
          >
            Har ikke problemer med pålogging
          </button>
        </div>
      </FeedbackForm>
    {:else if state === 'sent'}
      <FocusText elementType="h2" styling="margin: var(--spacing-32) 0;">
        <span>Takk for din tilbakemelding </span>
      </FocusText>
      <div class="sent-container">
        <button class="secondary" on:click={onClickCancel}
          ><span class="button-text-gradient" aria-hidden="true"
            >Tilbake til avstemningen</span
          >Tilbake til avstemningen</button
        >
      </div>
    {/if}
  </div>
  <button aria-label="Lukk" class="close-button" on:click={onClickCancel}>
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <div class="close-icon">{@html nrkClose}</div>
  </button>
</CoreDialog>

<style>
  h1 {
    margin-bottom: var(--spacing-8);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: var(--spacing-16) auto;
    padding: var(--spacing-16);
    max-width: 28rem;
  }
  .action-container {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
    padding-right: 1rem;
  }

  .action-container > :global(button) {
    margin: 0 var(--spacing-8);
  }

  .sent-container {
    margin: 1rem 0;
  }
  .back {
    color: var(--color-cart-text);
    background-color: transparent;
    text-decoration: underline;
  }

  .login-issues-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }

  .form-label {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .label-text {
    align-self: flex-start;
    text-align: left;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  textarea,
  input {
    color: var(--color-text);
    background-color: var(--color-bg);
    border: none;
    width: 100%;
  }

  .explanation {
    height: 8rem;
    padding: var(--spacing-8);
    border: 1px solid var(--color-button-secondary-text);
  }

  .email {
    border: 1px solid var(--color-button-secondary-text);
    padding: var(--spacing-8);
  }

  .back-button {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 1rem;
  }

  .close-button {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.1s;
  }

  .close-icon:hover {
    transform: scale(1.1);
  }
</style>
