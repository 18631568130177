export const dispatchConfetti = (numberOfStars: number) => {
  const event = new CustomEvent('confetti', {
    bubbles: true,
    cancelable: true,
    composed: true,
    detail: {
      numberOfStars,
    },
  });

  document.dispatchEvent(event);
};
