const loginIssuesStrings = [
  'får ikke logget meg inn',
  'får ikke logget meg på',
  'får ikke logget på',
  'får ikke logget inn',
  'får ikke logga meg inn',
  'får ikke logga meg på',
  'får ikke logga på',
  'får ikke logga inn',
  'problemer med pålogging',
  'problemer med innlogging',
  'pålogging fungerer ikke',
  'innlogging fungerer ikke',
  'pålogging funker ikke',
  'innlogging funker ikke',
  'fungerer ikke å logge inn',
  'fungerer ikke å logge på',
  'funker ikke å logge inn',
  'funker ikke å logge på',
  'det går ikke an å logge inn',
  'det går ikke an å logge på',
  'kan ikke logge inn',
  'kan ikke logge på',
  'ikke mulig å logge på',
  'ikke mulig å logge inn',
  'kan ikke logge seg inn',
  'kan ikke logge seg på',
  'umulig å logge seg inn',
  'umulig å logge seg på',
  'umulig å logge inn',
  'umulig å logge på',
  'umulig å få logget inn',
  'umulig å få logget på',
  'umulig å få logget seg inn',
  'umulig å få logget seg på',
  'umulig å få logga',
  'umulig å få logga',
  'får ikke logget seg inn',
  'får ikke logget seg på',
  'får ikke logga seg inn',
  'får ikke logga seg på',
];
export default loginIssuesStrings;
