<script lang="ts">
  import type { ClosingState } from '@pin/schemas/client-state-response';
  import { format } from 'date-fns';
  import { onMount } from 'svelte';
  import CouldNotVote from '../components/CouldNotVote.svelte';
  import Receipt from '../components/Receipt.svelte';
  import { trackEvent } from '../util/snowplow';
  import { sentVotes } from '../util/votesStore';
  import { showEndTime } from '../components/showtime';
  export let pollData: ClosingState;

  $: closeTime = format(new Date(pollData.endTime), "' kl. 'HH:mm");

  onMount(() => {
    trackEvent(
      { action: 'state:closed-poll', label: pollData.title },
      pollData.id
    );
  });
</script>

{#if $sentVotes}
  <!-- svelte-ignore a11y-autofocus -->
  <h2 autofocus tabindex="-1">Takk for at du stemte</h2>
  <p>Avstemningen stengte {closeTime}</p>
{:else}
  <!-- svelte-ignore a11y-autofocus -->
  <h2 autofocus tabindex="-1">
    Avstemningen stengte {closeTime}
  </h2>
  <h4>Sendingen var over ca. {showEndTime}</h4>
{/if}
{#if $sentVotes}
  <Receipt alternatives={pollData.alternatives} votes={$sentVotes} />
{:else}
  <CouldNotVote endTime={pollData.endTime} startTime={pollData.startTime} />
{/if}
