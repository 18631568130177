import {
  catchError,
  from,
  NEVER,
  of,
  repeat,
  switchMap,
  timeout,
  timer,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { getAppId } from '../api/feedback';
import { loginModelStore } from '../login/loginModel';
import { isVisible$ } from './rxjs.dom';
import { getSessionId } from './sessionId';
import { fromSvelteStore } from './svelte.rxjs';

type LoginStatus = {
  sessionId: string;
  appId: string;
  environment: string;
};

const getAccessToken$ = (accessTokenTimeoutMs: number) =>
  fromSvelteStore(loginModelStore).pipe(
    switchMap((loginModel) =>
      loginModel != null
        ? from(loginModel.getAccessToken()).pipe(timeout(accessTokenTimeoutMs))
        : NEVER
    ),
    catchError(() => of(null))
  );

const postToPinSight$ = (
  accessTokenTimeoutMs: number,
  pinsightSubmitTimeoutMs: number
) => {
  const payload: LoginStatus = {
    sessionId: getSessionId(),
    appId: getAppId(),
    environment: import.meta.env.VITE_APP_ENV,
  };
  return getAccessToken$(accessTokenTimeoutMs).pipe(
    switchMap((accessToken) =>
      fromFetch(`${import.meta.env.VITE_PINSIGHT_URL}/api/loginstatus`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(accessToken != null
            ? { Authorization: `Bearer ${accessToken}` }
            : {}),
        },
        body: JSON.stringify(payload),
      }).pipe(timeout(pinsightSubmitTimeoutMs))
    ),
    catchError(() => of(null))
  );
};
const initializePinsight = ({
  repeatIntervalMs = 120_000,
  accessTokenFetchTimeoutMs = 10_000,
  pinsightSubmitTimeoutMs = 10_000,
} = {}) => {
  isVisible$
    .pipe(
      switchMap((visible) =>
        visible
          ? postToPinSight$(
              accessTokenFetchTimeoutMs,
              pinsightSubmitTimeoutMs
            ).pipe(repeat({ delay: () => timer(repeatIntervalMs) }))
          : NEVER
      )
    )
    .subscribe();
};

export default initializePinsight;
