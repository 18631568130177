import {
  flushBuffer,
  newTracker,
  trackPageView as snowplowTrackPageView,
  trackStructEvent,
  type BrowserTracker,
} from '@snowplow/browser-tracker';
import type { ActionReturn } from 'svelte/action';
import { getSessionId } from './sessionId';

export const SNOWPLOW_APP_ID = `no.nrk.delta.stjernekamp`;
const TRACKER_ID = 'delta-tracker-generisk';
const HOST = import.meta.env.VITE_SNOWPLOW_URL;

let snowplowTracker: BrowserTracker | undefined | null = undefined;

export function initializeSnowPlowTracker() {
  snowplowTracker = newTracker(TRACKER_ID, HOST, {
    appId: SNOWPLOW_APP_ID,
    postPath: '/nrk/wd6',
    eventMethod: 'post',
    encodeBase64: import.meta.env.DEV ? false : true, //for debugging
    stateStorageStrategy: 'cookieAndLocalStorage',
    bufferSize: 10,
    respectDoNotTrack: true,
  });
}

export const setUser = (userId: string | null) => {
  snowplowTracker?.setUserId(userId);
};

export const trackPageView = () => {
  snowplowTrackPageView(
    {
      context: getContext(),
    },
    [TRACKER_ID]
  );
  debounceFlushEventsBuffer();
};

type ClickAction =
  | 'star'
  | 'could-not-vote-open'
  | 'feedback-submit'
  | 'feedback-close'
  | 'feedback-open'
  | 'add-vote'
  | 'remove-vote'
  | 'submit-votes'
  | 'submit-votes-disabled'
  | 'tooltip-close'
  | 'promo'
  | 'receipt-promo'
  | 'category-change'
  | 'promo-yes'
  | 'promo-no'
  | 'pre-open-select'
  | 'disabled-select'
  | 'immune-select';

type ErrorAction =
  | 'fetching-clientstate'
  | 'submit'
  | 'weird-submit'
  | 'feedback-submit'
  | 'initializing-confetti'
  | 'accesstoken-undefined'
  | string;

type StateAction =
  | 'before-poll'
  | 'open-poll'
  | 'closed-poll'
  | 'after-poll'
  | 'empty-poll'
  | 'login'
  | 'poster';

type Impression = 'promo';

type Action =
  | `click:${ClickAction}`
  | `error:${ErrorAction}`
  | `state:${StateAction}`
  | `impression:${Impression}`
  | `success:submit`
  | 'interaction';

type Event = Omit<Parameters<typeof trackStructEvent>[0], 'category'> & {
  action: Action;
};

export const trackEvent = (event: Event, contentId?: string) => {
  trackStructEvent(
    {
      ...event,
      category: 'PIN',
      context: getContext(contentId),
    },
    [TRACKER_ID]
  );
  debounceFlushEventsBuffer();
};

const getContext = (contentId?: string) => {
  const context: Array<{ schema: string; data: Record<string, string> }> = [
    {
      schema: 'iglu:no.nrk/service/jsonschema/2-0-0',
      data: {
        id: 'nrkno',
        environment: import.meta.env.VITE_APP_ENV,
      },
    },
  ];
  if (snowplowTracker?.getUserId()) {
    context.push({
      schema: 'iglu:no.nrk/nrk-user/jsonschema/1-0-0',
      data: {
        id: snowplowTracker?.getUserId() as unknown as string,
      },
    });
  }
  if (contentId != null) {
    context.push({
      schema: 'iglu:no.nrk/content/jsonschema/1-0-1',
      data: {
        id: contentId,
        kind: 'avstemning',
        source: 'midas',
      },
    });
  }
  context.push({
    schema: 'iglu:no.nrk/nrk-session/jsonschema/1-0-0',
    data: {
      id: getSessionId(),
    },
  });
  return context;
};

export function impression(
  element: HTMLElement,
  label: string
): ActionReturn<{ label: string }> {
  const intersectionObserverSupported =
    !!('IntersectionObserver' in window) &&
    !!('IntersectionObserverEntry' in window) &&
    !!('intersectionRatio' in window.IntersectionObserverEntry.prototype);

  const requiredViewTime = 1_000;

  if (intersectionObserverSupported) {
    let timeoutRef: number;

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          timeoutRef = window.setTimeout(() => {
            trackEvent({ action: 'impression:promo', label });
          }, requiredViewTime);
        }

        if (!entry.isIntersecting) {
          clearInterval(timeoutRef);
        }
      });
    };

    const observer = new IntersectionObserver(callback, {
      root: document.getElementById('root'),
      rootMargin: '0px',
      threshold: 0.8,
    });

    observer.observe(element);

    return {
      destroy() {
        observer.disconnect();
      },
    };
  }
  return {};
}

let debouncedFlushBufferTimeoutId: number | undefined;
const debounceFlushEventsBuffer = () => {
  clearTimeout(debouncedFlushBufferTimeoutId);
  const timeoutMs = randomNumberBetween(3_000, 10_000);
  debouncedFlushBufferTimeoutId = window.setTimeout(() => {
    flushBuffer();
    if (import.meta.env.DEV) {
      console.log('Flushed buffer');
    }
  }, timeoutMs);
  if (import.meta.env.DEV) {
    console.log(`flushBuffet scheduled in ${timeoutMs}`);
  }
};

function randomNumberBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    flushBuffer();
  }
});
