<script lang="ts">
  import type {
    Alternative,
    ClosingState,
    OpenState,
  } from '@pin/schemas/client-state-response';
  import * as Sentry from '@sentry/svelte';
  import { onMount } from 'svelte';
  import { postVote } from '../api/post-vote';
  import AlternativeItem from '../components/AlternativeItem.svelte';
  import AlternativeList from '../components/AlternativeList.svelte';
  import Receipt from '../components/Receipt.svelte';
  import VotingSlip from '../components/VotingSlip.svelte';
  import { pushAria } from '../util/ariaLive';
  import { dispatchConfetti } from '../util/confetti';
  import { captureError } from '../util/sentry';
  import { trackEvent } from '../util/snowplow';
  import {
    hasSeenSubmitTooltip,
    votingSlipTooltip,
  } from '../util/tooltipstore';
  import { sentVotes, votesCart } from '../util/votesStore';
  Sentry.trackComponent();

  export let pollData: OpenState | ClosingState;

  let submitted = !!$sentVotes?.length;
  $: votes = $votesCart ?? [];

  let submitting = false;

  const addVote = (alternativeId: string) => {
    votesCart.set([...(votes || []), alternativeId]);
    const alternativeTitle =
      pollData.alternatives.filter((a) => a.id === alternativeId)[0].title ??
      'alternativ';
    const votesLeft = pollData.maxVotes - ($votesCart?.length ?? 0);
    if (votesLeft === 0) {
      pushAria(`Lagt til ${alternativeTitle}, husk å sende inn stemmene dine.`);
    } else {
      pushAria(
        `Lagt til ${alternativeTitle}, du har ${votesLeft} ${
          votesLeft === 1 ? 'stemme' : 'stemmer'
        } igjen.`
      );
    }
    trackEvent(
      { action: 'click:add-vote', label: pollData.title },
      pollData.id
    );

    votingSlipTooltip.set(undefined);
  };

  const removeVote = (removeIndex: number) => () => {
    const alternativeId = votes[removeIndex];
    votesCart.set(votes.filter((_alternative, index) => index !== removeIndex));
    const alternativeTitle =
      pollData.alternatives.filter((a) => a.id === alternativeId)[0].title ??
      'alternativ';

    pushAria(`Fjernet ${alternativeTitle}`);
    trackEvent(
      { action: 'click:remove-vote', label: pollData.title },
      pollData.id
    );
    votingSlipTooltip.set(undefined);
  };

  const submit = async () => {
    const allVotesSelected = votes.length === pollData.maxVotes;
    if (!allVotesSelected) {
      trackEvent(
        { action: 'click:submit-votes-disabled', label: pollData.title },
        pollData.id
      );
      const votesLeft = pollData.maxVotes - ($votesCart?.length ?? 0);
      votingSlipTooltip.set(
        `${votesLeft} ${votesLeft === 1 ? 'stemme' : 'stemmer'} igjen`
      );
      return;
    }

    submitting = true;
    votingSlipTooltip.set(undefined);
    hasSeenSubmitTooltip.set(true);
    trackEvent(
      { action: 'click:submit-votes', label: pollData.title },
      pollData.id
    );
    await tryPostVote();
    try {
      sentVotes.set([...votes]);

      trackEvent(
        { action: 'success:submit', label: pollData.title },
        pollData.id
      );
      trackEvent({
        action: 'interaction',
      });
      submitted = true;
      pushAria('aria-live.submitted');
      dispatchConfetti(500);
    } catch (e) {
      // We saw some weird error:submit events
      // in snowplow coming in milliseconds after success:submit
      // so I want to log this and see if we can find out what it was.
      trackEvent({ action: 'error:weird-submit' }, pollData.id);
      captureError('error:weird-submit', e);
    } finally {
      submitting = false;
    }
  };

  const tryPostVote = async () => {
    try {
      await postVote(pollData.voteUrl, {
        pollId: pollData.id,
        systemId: 'generic-client',
        alternativeIds: votes,
      });
    } catch (_e) {
      trackEvent(
        { action: 'error:submit', label: pollData.title },
        pollData.id
      );
      votingSlipTooltip.set(`Fikk ikke sendt dine stemmer, prøv igjen`);
    }
  };

  $: votesLeft = pollData.maxVotes - votes.length;
  $: alternatives = pollData.alternatives.reduce<Record<string, Alternative>>(
    (prevValue, alternative) => ({
      ...prevValue,
      [alternative.id]: alternative,
    }),
    {}
  );
  $: teamOneAlternatives = pollData.alternatives.filter(
    (a) => a.smsId === 'lag-1'
  );
  $: teamTwoAlternatives = pollData.alternatives.filter(
    (a) => a.smsId !== 'lag-1'
  );

  onMount(() => {
    trackEvent(
      { action: 'state:open-poll', label: pollData.title },
      pollData.id
    );
  });
</script>

{#if submitted}
  <!-- svelte-ignore a11y-autofocus -->
  <h2 autofocus tabindex="-1">Takk for at du stemte</h2>
  <p>Dine stemmer har blitt registrert!</p>
  <Receipt alternatives={pollData.alternatives} {votes} />
{:else}
  <!-- svelte-ignore a11y-autofocus -->
  <h2 autofocus tabindex="-1">Stem på din(e) favoritter</h2>
  <h2>
    Du har
    <span class="number">{votesLeft}</span>
    {votesLeft === 1 ? 'stemme' : 'stemmer'} igjen
  </h2>
  <h3>Lag 1</h3>
  <AlternativeList>
    {#each teamOneAlternatives as alternative, i (alternative.id)}
      <AlternativeItem
        imagePos={i % 2 === 0 ? 'left' : 'right'}
        disabled={submitting || votes.length === pollData.maxVotes}
        {alternative}
        addVote={submitted ? undefined : addVote}
        {votes}
        {submitted}
      />
    {/each}
  </AlternativeList>
  <h3>Lag 2</h3>
  <AlternativeList>
    {#each teamTwoAlternatives as alternative, i (alternative.id)}
      <AlternativeItem
        imagePos={i % 2 === 0 ? 'left' : 'right'}
        disabled={submitting || votes.length === pollData.maxVotes}
        {alternative}
        addVote={submitted ? undefined : addVote}
        {votes}
        {submitted}
      />
    {/each}
  </AlternativeList>
  <div class="bg-gradient"></div>
{/if}

{#if !submitted}
  <VotingSlip
    {submitting}
    {votes}
    {alternatives}
    {submit}
    {removeVote}
    maxVotes={pollData.maxVotes}
  />
{/if}

<style>
  .number {
    color: var(--sk-pink);
  }

  h3 {
    margin-top: 1rem;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 500;
    font-size: 1.5rem;
  }

  p {
    max-width: 31rem;
  }

  .bg-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30rem;
    background: linear-gradient(
      0deg,
      #c423b0 0%,
      rgba(194, 35, 174, 0.98343) 6.15%,
      rgba(192, 34, 173, 0.961947) 12.3%,
      rgba(189, 34, 170, 0.935033) 18.47%,
      rgba(186, 33, 167, 0.90217) 24.68%,
      rgba(182, 33, 163, 0.862841) 30.93%,
      rgba(177, 32, 159, 0.816527) 37.26%,
      rgba(172, 31, 154, 0.762712) 43.67%,
      rgba(165, 30, 149, 0.700877) 50.18%,
      rgba(158, 28, 142, 0.630506) 56.81%,
      rgba(150, 27, 135, 0.551079) 63.57%,
      rgba(141, 25, 127, 0.462079) 70.49%,
      rgba(131, 23, 118, 0.362989) 77.57%,
      rgba(120, 21, 108, 0.253291) 84.84%,
      rgba(108, 19, 97, 0.132467) 92.31%,
      rgba(94, 17, 84, 0) 100%
    );
    opacity: 0.8;
    z-index: -1;
  }
</style>
