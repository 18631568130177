<script lang="ts">
  import { trackEvent } from '../util/snowplow';
  import type { Alternative } from '@pin/schemas/client-state-response';
  import AlternativeItem from './AlternativeItem.svelte';
  import AlternativeList from './AlternativeList.svelte';
  import { onMount } from 'svelte';
  import { userPrefersReducedMotion } from '@pin/dom-utils';
  import { laat_for_laat_url } from './Promo/Promos';

  const utmParams =
    '?utm_source=deltanrkno&utm_medium=delta&utm_campaign=stjernekamp';

  export let alternatives: Alternative[];
  export let votes: string[];

  const getAlternatives = (alternatives: Alternative[], votes: string[]) => {
    const voteMap: Record<string, number> = {};
    votes.forEach((vote) => {
      voteMap[vote] = voteMap[vote] ? voteMap[vote] + 1 : 1;
    });

    return alternatives
      .filter((alternative) => voteMap[alternative.id])
      .sort((a, b) => voteMap[b.id] - voteMap[a.id]);
  };

  $: alternatives = getAlternatives(alternatives, votes);

  onMount(() => {
    const behavior = userPrefersReducedMotion() ? 'auto' : 'smooth';
    window.scrollTo({
      top: 0,
      behavior,
    });
  });
</script>

<AlternativeList>
  {#each alternatives as alternative, i (alternative.id)}
    <AlternativeItem
      imagePos={i % 2 === 0 ? 'left' : 'right'}
      {alternative}
      {votes}
      submitted
    />
  {/each}
</AlternativeList>

<div class="link-container">
  <a
    class="text"
    href={laat_for_laat_url + utmParams}
    on:click={() => trackEvent({ action: 'click:receipt-promo' })}
    target="_blank"
  >
    <div class="header">Er du enig med anmelderen?</div>
    <div class="link">Les anmeldelsen og se kveldens opptredner</div>
  </a>
</div>

<style>
  .link-container {
    margin-top: 5rem;
    width: 100%;
    max-width: 31rem;
    padding: 1rem;
    height: 6rem;
    background: var(--nrk-cool-pink-950, #260026);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  a {
    padding: 0;
    height: 100%;
    display: grid;
    place-items: center;
  }

  .header {
    font-size: 1.4rem;
    font-weight: 700;
  }
  .link {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: var(--sk-pink);
    text-underline-offset: 5px;
  }
</style>
