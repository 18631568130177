<script lang="ts">
  import type { Alternative } from '@pin/schemas/client-state-response';
  import * as Sentry from '@sentry/svelte';
  import { onMount } from 'svelte';
  import {
    hasSeenSubmitTooltip,
    votingSlipTooltip,
  } from '../util/tooltipstore';
  import SelectedAlternative from './SelectedAlternative.svelte';
  import Tooltip from './Tooltip.svelte';
  Sentry.trackComponent();

  export let maxVotes: number;
  export let votes: string[];
  export let alternatives: Record<string, Alternative>;
  export let submitting: boolean;
  export let submit: () => void;
  export let removeVote: (index: number) => () => void;

  let votingSlipRef: HTMLDivElement;

  $: voteSpots = new Array<string>(maxVotes);
  $: allVotesSelected = votes.length === maxVotes;

  // We have to adjust the height of the footer so it matches the height of the voting-slip
  onMount(() => {
    const footer = document.getElementById('footer');

    if (footer && votingSlipRef) {
      const footerStyle = window.getComputedStyle(footer);
      let initialPadding = footerStyle.paddingBottom;
      const votingSlipHeight = votingSlipRef.clientHeight;
      footer.style.setProperty(
        'padding-bottom',
        `calc(${initialPadding} + ${votingSlipHeight}px)`
      );
      return () => {
        footer.style.setProperty('padding-bottom', initialPadding);
      };
    }
    return;
  });

  const selectedCountChanged = (allSelected: boolean) => {
    if (allSelected && !$hasSeenSubmitTooltip) {
      votingSlipTooltip.set(`Husk å sende inn stemmene dine`);
      hasSeenSubmitTooltip.set(true);
    } else {
      votingSlipTooltip.set(undefined);
    }
  };

  $: selectedCountChanged(allVotesSelected);
</script>

<div bind:this={votingSlipRef} class="voting-slip">
  <div>
    <div class="info">
      {#if submitting}
        Sender dine stemmer...
      {:else if allVotesSelected}
        Trykk
        <strong class="vote">Stem</strong>
        for å sende inn dine stemmer
      {:else}
        <strong>Velg</strong> én eller flere artister for å stemme
      {/if}
    </div>
  </div>
  <div class="votes">
    <ul class="vote-list">
      <!-- eslint-disable-next-line svelte/require-each-key -->
      {#each voteSpots as _value, index}
        <li class:empty={alternatives[votes[index]] == undefined}>
          <SelectedAlternative
            alternative={alternatives[votes[index]]}
            removeVote={removeVote(index)}
            disabled={submitting}
          />
        </li>
      {/each}
    </ul>
    <div class="submit-container">
      <Tooltip align="right" bind:tooltipText={$votingSlipTooltip}>
        <div
          class="submit-glower"
          class:highlighted={allVotesSelected && !submitting}
          class:submitting
        >
          <button
            data-test-id="submit"
            class="submit primary"
            class:disabled={!allVotesSelected}
            on:click={submit}
            disabled={submitting}
            aria-disabled={submitting || !allVotesSelected}
          >
            <span class="button-text-gradient" aria-hidden="true"
              >{submitting ? 'Sender' : 'Stem'}</span
            >
            {#if submitting}
              Sender
            {:else}
              Stem
            {/if}
          </button>
        </div>
      </Tooltip>
    </div>
  </div>
</div>

<style>
  .voting-slip {
    position: fixed;
    bottom: 0;
    /* hack to get an even box-shadow */
    width: 110vw;
    box-shadow: 0px -12px 36px var(--color-cart-shadow);
    color: var(--color-cart-text);
    background: var(--sk-cart-background, var(--color-cart-bg));
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    text-align: center;
    padding: var(--spacing-16);
    padding-bottom: var(--spacing-32);
  }

  .info {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-xs);
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    line-height: 1.2;
    padding: 0 var(--spacing-16) var(--spacing-16) var(--spacing-16);
  }

  .votes {
    display: flex;
    justify-content: center;
  }

  .vote-list {
    display: flex;
    margin: 0 var(--spacing-8);
  }

  li {
    background-color: black;
    border-radius: 9999px;
    width: 2.75rem;
    height: 2.75rem;

    /* hack to get border with gradient */
    background: var(--sk-selected-alternative-bg);
    border-radius: 50em;
    border: 2px solid transparent;
    margin: 0 var(--spacing-8);
  }

  li.empty {
    background: var(--sk-not-selected-alternative-bg);
  }

  .submit-container {
    --vertical-padding: 0.5rem;
    margin-top: calc(var(--vertical-padding) * -1);
  }

  .submit {
    padding: var(--vertical-padding) 1.125rem;
  }

  .submit .button-text-gradient {
    padding: var(--vertical-padding) 1.125rem;
  }

  .submit-glower {
    padding: 0.5rem;
    overflow: hidden;
    position: relative;
  }

  .submit-glower::before,
  .submit-glower::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    z-index: -1;
  }

  .submit-glower.highlighted::after {
    background-color: var(--sk-supah-pink, var(--color-button-primary-bg));
    animation: ping 1.7s ease-out infinite;
  }

  .submit-glower.submitting::after {
    width: 140%;
    height: 200%;
    left: -10%;
    background: var(--sk-secondary-pink, var(--color-button-primary-bg));
    opacity: 0.6;
    animation: spin 6s ease-in-out infinite;
  }
  .submit-glower.submitting::before {
    width: 140%;
    height: 200%;
    left: -10%;
    background: var(--sk-supah-pink, var(--color-button-primary-bg));
    opacity: 0.6;
    animation: spin 4s ease-in-out infinite;
  }

  .submit-glower.highlighted:hover::after,
  .submit-glower.highlighted:focus::after {
    animation: none;
    background-color: transparent;
  }

  @keyframes ping {
    75%,
    100% {
      transform: scale(1.1, 1.5);
      opacity: 0;
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(1turn);
    }
  }

  .disabled,
  .disabled:hover,
  .disabled:active,
  .disabled:focus {
    color: var(--color-button-primary-disabled-text);
    background-color: var(--color-button-primary-disabled-bg);
  }

  .disabled:active {
    transform: none;
    animation: tilt-shaking 0.2s ease-in-out 2;
  }

  .vote {
    text-transform: uppercase;
  }
</style>
