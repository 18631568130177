import { writable } from 'svelte/store';

export const nrkCtx = 'stjernekamp';

export type Session =
  | {
      type: 'unauthenticated';
    }
  | {
      type: 'authenticated';
      sub: string;
      name: string;
    }
  | undefined;

export type LoginModel = {
  login: () => Promise<void>;
  gotoUserPage: () => void;
  session: Session;
  getAccessToken: () => Promise<string | null | undefined>;
  logout?: () => void;
  loginTestUser?: (username: string) => Promise<void>;
};

export const loginModelStore = writable<LoginModel | null>(null);
