import * as Sentry from '@sentry/svelte';
import { getSessionId } from './sessionId';
import { ignoreErrors } from './ignoreSentryErrors';

const environment =
  import.meta.env.VITE_APP_ENV === 'preprod'
    ? `stjernekamp-preprod`
    : 'stjernekamp';

export const initializeSentry = () => {
  if (!import.meta.env.DEV) {
    Sentry.init({
      dsn: 'https://a3144c12e1ff4f02b275563e6d00e838@o124059.ingest.sentry.io/4505170895699968',
      release: import.meta.env.VITE_FULL_VERSION,
      environment,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
      ],
      sampleRate: import.meta.env.VITE_APP_ENV === 'prod' ? 0.2 : 1,
      tracesSampleRate: 0.05,
      ignoreErrors,
    });
    Sentry.setUser({
      id: getSessionId(),
    });
  }
};

export function captureHttpError(message: string, response: Response) {
  captureError(message, new Error(message), {
    statusCode: response.status.toString(),
    statusText: response.statusText,
    headers: JSON.stringify(response.headers),
    failedUrl: response.url,
  });
}

export function captureError(
  message: string,
  error: unknown,
  customProperties?: Record<string, string>
) {
  if (error instanceof Error) {
    Sentry.captureException(error, customProperties);
  } else {
    if (import.meta.env.DEV) console.error(message, error, customProperties);
  }
}
