import type { Vote } from '@nrk/pin-votes-schemas/vote-post';
import { jsonSuccessSelector, retryFetch } from '@pin/client-http-utils/rxjs';
import {
  from,
  lastValueFrom,
  mergeMap,
  switchMap,
  throwError,
  timeout,
  timer,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { loginModelStore } from '../login/loginModel';
import { get } from 'svelte/store';

export const postVote = async (url: string, vote: Vote) => {
  return await lastValueFrom(
    from(getAccessToken()).pipe(
      switchMap((accessToken) =>
        accessToken != null
          ? postVoteFetch(url, vote, accessToken).pipe(
              // abort after at least 6 seconds
              // in 2023 we experienced that some users ended up with "try again"
              // because Akamai was struggling to serve traffic to our vote api
              // in less than 2 seconds which was our timeout then.
              timeout(6_000),
              retryFetch(4)
            )
          : // If accessToken is null there might be some issues with innlogging
            // so we wait 5 seconds to not give the retry option to the user too early.
            timer(5_000).pipe(
              mergeMap(() => throwError(() => new Error('No token')))
            )
      )
    )
  );
};

const postVoteFetch = (url: string, vote: Vote, accessToken: string) =>
  fromFetch(`${url}/api/vote`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(vote),
    selector: jsonSuccessSelector<Vote>,
  });

const getAccessToken = async () => {
  const loginModel = get(loginModelStore);
  if (loginModel == null) {
    return undefined;
  }
  return await loginModel.getAccessToken();
};
