// src/index.ts
var parseRetryAfterHeader = (response) => {
  const retryAfter = response.headers.get("retry-after");
  if (!retryAfter) {
    return;
  }
  if (Number.isFinite(Number(retryAfter))) {
    return Number(retryAfter) * 1e3;
  }
  const date = Date.parse(retryAfter);
  if (Number.isNaN(date)) {
    return;
  }
  return date - Date.now();
};
var HttpError = class extends Error {
  constructor(response) {
    super(`${response.status} for ${response.url}`);
    this.name = "HttpError";
    this.response = response;
  }
};

export {
  parseRetryAfterHeader,
  HttpError
};
