// src/index.ts
import { HttpError } from "@pin/client-http-utils";
import { retryFetch } from "@pin/client-http-utils/rxjs";
import {
  catchError,
  map as map2,
  merge,
  NEVER,
  of,
  repeat,
  startWith as startWith2,
  switchMap,
  timeout,
  timer
} from "rxjs";
import { fromFetch } from "rxjs/fetch";

// src/dom.ts
import {
  distinctUntilChanged,
  fromEvent,
  map,
  shareReplay,
  startWith
} from "rxjs";
var isVisible$ = fromEvent(document, "visibilitychange").pipe(
  map(() => document.visibilityState === "visible"),
  startWith(true),
  distinctUntilChanged(),
  shareReplay({ refCount: true, bufferSize: 1 })
);

// src/index.ts
var emptyStateId = "not-received";
var emptyState = {
  id: emptyStateId,
  title: "",
  description: "",
  alternatives: [],
  type: "before"
};
var clientStateObservable = ({
  apiUrl,
  clientId,
  retry = 3,
  timeout: timeoutMs = 1e4,
  errorDelay = 5e3,
  repeatDelay = 2e4,
  onError = () => {
  }
}) => isVisible$.pipe(
  switchMap(
    (visible) => visible ? fetchState(apiUrl, clientId).pipe(
      timeout(timeoutMs),
      retryFetch(retry),
      catchError((err, source) => {
        onError(err);
        return timer(errorDelay).pipe(switchMap(() => source));
      }),
      repeat({ delay: () => timer(repeatDelay) })
    ) : NEVER
  )
).pipe(emitWhenClosed, startWith2(emptyState));
var fetchState = (apiUrl, clientId) => fromFetch(`${apiUrl}/api/state/${getClientId(clientId)}`, {
  selector: (response) => {
    if (!response.ok) {
      throw new HttpError(response);
    }
    return response.json();
  }
});
var emitWhenClosed = switchMap(
  (state) => state.type === "closing" ? merge(of(state), timer(new Date(state.endTime)).pipe(map2(() => state))) : of(state)
);
var getClientId = (clientId) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("client-id") ?? clientId;
};
export {
  clientStateObservable,
  emptyStateId
};
