<script lang="ts">
  import { onMount, onDestroy } from 'svelte';
  import { checkAutoplay } from '@pin/dom-utils';
  import bgImageUrl from '../assets/Background.jpg';
  import bgVideoUrl from '../assets/Background.mp4';

  let showthumbnail = true;
  let shouldAutoPlay = checkAutoplay();
  let landscapetimeoutId: ReturnType<typeof setTimeout>;
  let landscapeVideo: HTMLVideoElement;

  const landscapeBackgroundImageData = {
    videoFileName: bgVideoUrl,
    stillFileName: bgImageUrl,
    name: 'landscape',
  };

  onMount(async () => {
    if (landscapeVideo != null) {
      // this.videoIsPlaying = !video.paused;
      landscapeVideo.addEventListener(
        'playing',
        () => {
          landscapetimeoutId = setTimeout(() => {
            landscapeVideo.currentTime = 0;
            showthumbnail = false;
          }, 500);
        },
        { once: true }
      );
    }
  });

  onDestroy(() => {
    clearTimeout(landscapetimeoutId);
  });
</script>

<div class="background" aria-hidden="true" data-nosnippet>
  <figure>
    <div
      class="image-wrapper {landscapeBackgroundImageData.name}"
      style="--bgurl: url({landscapeBackgroundImageData.stillFileName});"
    >
      <video
        bind:this={landscapeVideo}
        style="--opacity: {showthumbnail ? 0 : 1};"
        preload="metadata"
        muted={true}
        poster={landscapeBackgroundImageData.stillFileName}
        playsinline={true}
        loop={true}
        disableremoteplayback={true}
        autoplay={shouldAutoPlay}
      >
        <source
          type="video/mp4"
          src={landscapeBackgroundImageData.videoFileName}
        />
      </video>
    </div>
  </figure>
</div>

<style>
  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: calc(115 * var(--initialVh));
    z-index: -1;
    filter: blur(2px) brightness(0.6);
  }

  .image-wrapper {
    background-image: var(--bgurl);
    background-position:
      center center,
      center center;
    background-size: cover;
  }

  .image-wrapper,
  .background video,
  .background figure {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    display: block;
    object-fit: cover;
  }

  video {
    opacity: var(--opacity);
    transition: opacity 700ms;
  }
</style>
