<script lang="ts">
  import { dispatchConfetti } from '../util/confetti';
  import trackClickDebounced from '../util/starClickTracking';
  import { onMount, onDestroy, tick } from 'svelte';

  let intervalId: number | undefined = undefined;
  let intervalId2: number | undefined = undefined;
  let pressed = false;

  const colors: string[] = ['#ff52f1', '#ff99f7', '#ffdefc', 'white'];

  let buttonref: HTMLButtonElement;
  $: stars = buttonref?.querySelectorAll('svg') ?? [];
  let active = false;
  const shift = (
    image: SVGElement,
    index: number,
    rangeX: number,
    rangeY: number
  ) => {
    const translationIntensity = active ? 24 : 4;
    const maxTranslation = translationIntensity * (index + 1);
    const currentTranslation = `${maxTranslation * rangeX}% ${
      maxTranslation * rangeY
    }%`;

    const scale = active ? 1 + index * 0.4 : 1;
    image.style.filter = 'none';

    if (image.animate != undefined) {
      image.animate(
        {
          translate: currentTranslation,
          scale,
        },
        { duration: 750, fill: 'forwards', easing: 'ease' }
      );
    }
  };

  const shiftAll = (
    stars: NodeListOf<SVGSVGElement>,
    rangeX: number,
    rangeY: number
  ) => stars.forEach((star, index) => shift(star, index, rangeX, rangeY));

  const shiftStar = (e: TouchEvent, stars: NodeListOf<SVGSVGElement>) => {
    const rect = buttonref?.getBoundingClientRect(),
      radius = 200;

    const centerX = rect.left + rect.width / 2,
      centerY = rect.top + rect.height / 2;

    const rangeX = (e.touches[0].clientX - centerX) / radius,
      rangeY = (e.touches[0].clientY - centerY) / radius;
    shiftAll(stars, rangeX, rangeY);
  };

  const resetStar = () => {
    active = false;
    shiftAll(stars, 0.4, -0.7);
  };

  const handleStart = () => {
    trackClickDebounced();
    pressed = true;
    if (!intervalId && !intervalId2) {
      intervalId = window.setInterval(() => dispatchConfetti(3), 100);
      intervalId2 = window.setInterval(() => dispatchConfetti(200), 10000);
      window.addEventListener('touchmove', touchmoveEventListener, {
        passive: false,
      });
    }
  };

  const handleStop = () => {
    clearInterval(intervalId);
    clearInterval(intervalId2);
    intervalId = undefined;
    intervalId2 = undefined;
    window.removeEventListener('touchmove', touchmoveEventListener);
  };

  const touchmoveEventListener = (e: TouchEvent) => {
    e.preventDefault();
    shiftStar(e, stars);
    buttonref.style.touchAction = 'none';
  };

  const touchstartEventListener = (e: TouchEvent) => {
    e.preventDefault();
    shiftStar(e, stars);
    active = true;
  };

  const touchendEventListener = () => {
    buttonref.style.touchAction = 'unset';
    resetStar();
  };

  onMount(async () => {
    buttonref.addEventListener('touchstart', touchstartEventListener, {
      passive: false,
    });

    document.body.addEventListener('touchend', touchendEventListener);
    await tick();
    resetStar();
  });

  onDestroy(() => {
    window.removeEventListener('touchmove', touchmoveEventListener);
    buttonref.removeEventListener('touchstart', touchstartEventListener);
    document.body.removeEventListener('touchend', touchendEventListener);
    handleStop();
  });
</script>

<button
  on:mousedown={handleStart}
  on:mouseup={handleStop}
  on:mouseleave={handleStop}
  on:touchstart={handleStart}
  on:touchend={handleStop}
  bind:this={buttonref}
  id="button"
  aria-hidden="true"
  tabindex="-1"
  class:pressed
  class:active
>
  <!-- eslint-disable-next-line svelte/require-each-key -->
  {#each colors as color}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 44 42"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.1836 15.9813L21.992 0L16.8004 15.9813H0L13.5918 25.8582L8.40019 41.8395L21.992 31.9626L35.5838 41.8395L30.3922 25.8582L43.984 15.9813H27.1836ZM24.4993 19.6769L21.9922 11.9593L19.4851 19.6769H11.3719L17.9356 24.4466L15.4285 32.1643L21.9922 27.3945L28.5559 32.1643L26.0488 24.4466L32.6125 19.6769H24.4993Z"
        fill={color}
        shape-rendering="geometricPrecision"
      />
    </svg>
  {/each}
</button>

<style>
  button {
    position: relative;
    background: transparent;
    height: 48px;
    width: 48px;
    border: none;
    box-shadow: none;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    transition: all 1s;
  }
  button.active {
    z-index: 1;
  }
  button.pressed {
    animation: none;
  }

  button {
    animation: wiggle 10s ease-in infinite;
  }

  @keyframes wiggle {
    0%,
    90% {
      transform: rotate(0deg);
    }
    91% {
      transform: rotate(-15deg);
    }
    92% {
      transform: rotate(15deg);
    }
    94% {
      transform: rotate(-7deg);
    }
    96% {
      transform: rotate(7deg);
    }
    98% {
      transform: rotate(-3deg);
    }
    99% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
</style>
