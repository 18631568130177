import { createCachedStore } from './cacheStore';
import { writable } from 'svelte/store';

export const hasSeenSubmitTooltip = createCachedStore(
  'session',
  'submit-tooltip',
  false
);

export const votingSlipTooltip = writable<string | undefined>(undefined);
