<script lang="ts">
  import * as Sentry from '@sentry/svelte';
  import { onDestroy, onMount } from 'svelte';
  import { captureError } from '../../util/sentry';
  import { trackEvent } from '../../util/snowplow.js';
  import { showWebGlAnimations } from './usewebglStore.js';
  import { initWebGl } from './webgl.js';
  Sentry.trackComponent();

  let webglCanvas: HTMLCanvasElement;
  let context: WebGLRenderingContext & { canvas: HTMLCanvasElement };
  let startConfetti: undefined | ((numberOfParticles?: number) => void);
  let stopConfetti: undefined | (() => void);
  export const fireConfetti = (numberOfParticles: number = 500) => {
    startConfetti?.(numberOfParticles);
  };

  onMount(() => {
    if ($showWebGlAnimations === false) return;
    context = webglCanvas.getContext('webgl') as WebGLRenderingContext & {
      canvas: HTMLCanvasElement;
    };
    try {
      if (context.canvas == null) {
        // try to capture this https://sentry.io/organizations/nrk/issues/3875025510/
        throw new Error('No canvas on glContext');
      }
      const confettiContext = initWebGl(context);
      startConfetti = confettiContext.startConfetti;
      stopConfetti = confettiContext.stopConfetti;
    } catch (error) {
      trackEvent({ action: 'error:initializing-confetti' });
      captureError('error:initializing-confetti', error);
      showWebGlAnimations.set(false);
    }
  });

  onDestroy(() => {
    stopConfetti?.();
  });
</script>

<canvas bind:this={webglCanvas} aria-hidden="true"></canvas>

<style>
  canvas {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
</style>
