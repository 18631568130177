<script lang="ts">
  import { ariaLive } from '../util/ariaLive';
  export let mode: 'assertive' | 'polite';

  $: text = $ariaLive;
  $: debugAriaLive(text);
  const debugAriaLive = (text?: string) => {
    if (import.meta.env.DEV && text != undefined) {
      console.log(`Aria Live: ${text}`);
    }
  };
</script>

<div class="sr-only" aria-live={mode}>
  {#if text}
    <span>
      {text}
    </span>
  {/if}
</div>
