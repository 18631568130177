import { loginModelStore } from './loginModel';

const initWebproxyLogin = async () => {
  const { createWebproxyLoginModel } = await import('./webproxylogin');
  const loginModel = await createWebproxyLoginModel();
  loginModelStore.set(loginModel);
};

const initPinLogin = async () => {
  const { createPinLoginModel } = await import('./pinlogin');
  const loginModel = await createPinLoginModel();
  loginModelStore.set(loginModel);
};

if (import.meta.env.VITE_PIN_DELTA_AUTH_LOGIN === 'delta-auth') {
  initPinLogin();
} else {
  initWebproxyLogin();
}
