<script lang="ts">
  import type { Alternative } from '@pin/schemas/client-state-response';
  import Star from './Star.svelte';
  import Tooltip from './Tooltip.svelte';
  import { trackEvent } from '../util/snowplow';

  export let imagePos: 'left' | 'right';
  export let alternative: Alternative;
  export let addVote: ((alternativeId: string) => void) | undefined = undefined;
  export let votes: string[] | undefined = undefined;
  export let disabled = false;
  export let submitted = false;

  let tooltip: string | undefined = undefined;
  const onClick = (alternativeId: string) => () => {
    if (!addVote) {
      tooltip = 'Avstemningen har ikke åpnet enda';
      trackEvent({ action: 'click:pre-open-select' });
    } else if (!disabled) {
      addVote(alternativeId);
    } else {
      trackEvent({ action: 'click:disabled-select' });
    }
  };

  $: voteCounter = votes?.filter((vote) => vote === alternative.id) ?? [];
</script>

<li
  class:voteable={addVote != undefined}
  class="{imagePos} {alternative.smsId}"
>
  <picture>
    <source srcset={alternative.image.src} type="image/webp" />
    <source srcset={alternative.image.fallbackSrc} type="image/jpg" />
    <img
      src="{alternative.image.fallbackSrc}.jpg"
      alt={alternative.image.alt}
    />
  </picture>
  <div class="content">
    <h3
      data-title={alternative.title}
      style="--title-length: {alternative.title.length};"
    >
      {alternative.title}
    </h3>
    {#if !submitted}
      <Tooltip
        align={imagePos === 'left' ? 'right' : 'left'}
        bind:tooltipText={tooltip}
      >
        <button
          class="secondary"
          class:disabled={disabled || !addVote}
          on:click={onClick(alternative.id)}
          aria-disabled={disabled || !addVote}
          data-test-id="select-alternative"
          ><span class="button-text-gradient" aria-hidden="true">Velg</span
          >Velg</button
        >
      </Tooltip>
    {:else}
      <div
        class="votes"
        aria-label={`${voteCounter.length} ${voteCounter.length <= 1 ? 'stemme' : 'stemmer'}`}
      >
        <!-- eslint-disable-next-line svelte/require-each-key -->
        {#each voteCounter as _vote}
          <Star />
        {/each}
      </div>
    {/if}
  </div>
</li>

<style>
  li {
    width: 100%;
    max-width: 31rem;
    display: grid;
    align-items: center;
    border-left: 1px solid #b6f6ff;
    border-top: 1px solid #b6f6ff;
    border-bottom: 1px solid #1f5c8a;
    border-right: 1px solid #1f5c8a;

    margin: var(--spacing-8) 0;
    background: rgb(128, 220, 255);
    background: linear-gradient(
      var(--rotation),
      rgba(128, 220, 255, 1) 0%,
      rgba(0, 0, 0, 0) 80%
    );
  }

  .lag-2 {
    background: rgb(128, 220, 255);
    background: linear-gradient(
      var(--rotation),
      #fbaefc 0%,
      rgba(0, 0, 0, 0) 80%
    );
  }

  .right {
    grid-template-columns: 1fr auto;
    --rotation: 15deg;
  }

  .left {
    grid-template-columns: auto 1fr;
    --rotation: 350deg;
  }

  img {
    height: 10rem;
    width: 10rem;
  }

  .right picture {
    order: 1;
    justify-self: end;
  }

  .content {
    padding: 0 1rem;
    width: 100%;
  }

  .left .content {
    justify-self: end;
  }

  h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-size: clamp(1.25rem, calc(1.5rem * 8 / var(--title-length)), 1.5rem);
    line-height: 2rem;
    font-weight: 950;
    position: relative;
    text-shadow:
      1.3136942386627197px 1.3136942386627197px 0px #556fd3,
      0.9852706789970398px 0.9852706789970398px 0px #8c5ade;
    margin-bottom: var(--spacing-8);
  }

  button {
    width: 100%;
    max-width: 12rem;
  }

  .votes {
    display: flex;
    justify-content: center;
    width: 100%;
  }
</style>
