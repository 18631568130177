<script lang="ts">
  import { trackEvent } from '../util/snowplow';
  import { nrkClose } from '@nrk/core-icons';

  export let align: 'left' | 'right';
  export let tooltipText: string | undefined;

  const onClose = (event: Event) => {
    event.stopPropagation();
    trackEvent({ action: 'click:tooltip-close' });
    tooltipText = undefined;
  };
  $: visible = !!tooltipText;
</script>

<div class="container">
  {#if visible}
    <button
      class="tooltip {align}"
      on:click={onClose}
      title="Trykk for å skjule meldingen"
    >
      <div class="text">{tooltipText}</div>
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      <div class="remove-prefix">{@html nrkClose}</div>
    </button>
  {/if}
  <slot />
</div>

<style>
  .container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tooltip {
    letter-spacing: unset;
    text-transform: unset;
    position: absolute;
    bottom: 150%;
    border: 5px solid var(--color-cart-remove-bg);
    background: rgb(0, 100, 192);
    background: linear-gradient(
      180deg,
      rgba(0, 100, 192, 1) 0%,
      rgba(0, 171, 209, 1) 100%
    );
    width: 230px;
    font-weight: 400;
    font-size: var(--font-size-m);
    padding: var(--spacing-10);
    display: flex;
  }
  .tooltip:before {
    content: '';
    position: absolute;
    display: block;
    padding: unset;

    border-width: 20px 20px 0;
    border-style: solid;
    border-color: var(--color-cart-remove-bg) transparent;
  }
  .tooltip:after {
    content: '';
    display: block;
    position: absolute;
    border-width: var(--spacing-16);
    border-color: transparent;
    border-top-color: rgba(0, 171, 209, 1);
    border-style: solid;
    width: var(--spacing-16);
    height: var(--spacing-16);
  }

  .right {
    right: 0;
  }

  .right:before {
    top: 100%;
    right: 16.5%;
  }

  .right.tooltip:after {
    top: calc(100% - 2px);
    right: 18%;
  }

  .left {
    left: 0;
  }

  .left:before {
    top: 100%;
    left: 16.5%;
  }

  .left:after {
    top: calc(100% - 2px);
    left: 18%;
  }

  .remove-prefix {
    position: absolute;
    top: 0;
    right: 1rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 9999px;
    color: black;
    background-color: var(--color-cart-remove-bg);
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    animation: scaleIn 0.2s ease-in-out forwards 0.05s;
    transform: scale(0);
    transform-origin: bottom left;
  }
  .remove-prefix:disabled {
    filter: brightness(0.5) opacity(0.7);
  }
  @keyframes scaleIn {
    from {
      transform: translate(1.75rem, -0.6rem) scale(0);
    }
    to {
      transform: translate(1.75rem, -0.6rem) scale(1);
    }
  }
  .text {
    padding: var(--spacing-8);
    color: white;
  }
</style>
