export function mglResizeCanvas(
  glContext: WebGLRenderingContext & { canvas: HTMLCanvasElement }
) {
  const devicePixelRatio = window.devicePixelRatio;

  const displayWidth = Math.floor(
    glContext.canvas.clientWidth * devicePixelRatio
  );
  const displayHeight = Math.floor(
    glContext.canvas.clientHeight * devicePixelRatio
  );

  if (
    glContext.canvas.width !== displayWidth ||
    glContext.canvas.height !== displayHeight
  ) {
    glContext.canvas.width = displayWidth;
    glContext.canvas.height = displayHeight;
  }

  glContext.viewport(0, 0, glContext.canvas.width, glContext.canvas.height);
}

export class Shader {
  vertexShader: any;
  fragmentShader: any;
  shaderProgram: any;
  attributes: Map<any, any>;
  uniforms: Map<any, any>;
  glContext: WebGLRenderingContext;
  constructor(
    vertexShaderSource: string,
    fragmentShaderSource: string,
    glContext: WebGLRenderingContext & { canvas: HTMLCanvasElement }
  ) {
    this.glContext = glContext;

    // Compile the vertex shader source
    // console.log('Compiling vertex shader');
    this.vertexShader = this.compileShader(
      vertexShaderSource,
      this.glContext.VERTEX_SHADER
    );

    // console.log('Compiling fragment shader');
    // Compile the fragment shader source
    this.fragmentShader = this.compileShader(
      fragmentShaderSource,
      this.glContext.FRAGMENT_SHADER
    );

    // Create a shader program out of the compiled vertex and fragment sources
    this.shaderProgram = this.glContext.createProgram();
    this.glContext.attachShader(this.shaderProgram, this.vertexShader);
    this.glContext.attachShader(this.shaderProgram, this.fragmentShader);
    this.glContext.linkProgram(this.shaderProgram);

    // Handle linking errors
    const linkStatus = this.glContext.getProgramParameter(
      this.shaderProgram,
      this.glContext.LINK_STATUS
    );
    if (linkStatus !== true) {
      throw new Error(
        this.glContext.getProgramInfoLog(this.shaderProgram) ??
          'linking shaderProgram failed'
      );
    }

    // Manage the shader attributes
    this.attributes = new Map();

    for (
      let i = 0;
      i <
      this.glContext.getProgramParameter(
        this.shaderProgram,
        this.glContext.ACTIVE_ATTRIBUTES
      );
      i++
    ) {
      const attribute = this.glContext.getActiveAttrib(this.shaderProgram, i)!;
      const attributeLocation = this.glContext.getAttribLocation(
        this.shaderProgram,
        attribute.name
      );
      this.attributes.set(
        attribute.name,
        new ShaderAttribute(
          attribute.name,
          attribute.size,
          attribute.type,
          attributeLocation
        )
      );
    }

    // Manage the shader uniforms
    this.uniforms = new Map();

    for (
      let i = 0;
      i <
      this.glContext.getProgramParameter(
        this.shaderProgram,
        this.glContext.ACTIVE_UNIFORMS
      );
      i++
    ) {
      const uniform = this.glContext.getActiveUniform(this.shaderProgram, i)!;
      //console.log('uniform.name ' + uniform.name);
      const uniformLocation = this.glContext.getUniformLocation(
        this.shaderProgram,
        uniform.name
      )!;
      this.uniforms.set(
        uniform.name,
        new ShaderUniform(
          uniform.name,
          uniform.size,
          uniform.type,
          uniformLocation
        )
      );
    }
  }

  compileShader(source: string, type: number) {
    const shader = this.glContext.createShader(type)!;
    // console.log(source);
    this.glContext.shaderSource(shader, source);
    this.glContext.compileShader(shader);

    // Handle compilation errors
    const compilestatus = this.glContext.getShaderParameter(
      shader,
      this.glContext.COMPILE_STATUS
    );
    if (compilestatus !== true) {
      throw new Error(
        this.glContext.getShaderInfoLog(shader) ??
          'Error compiling shader type ' + type
      );
    }

    return shader;
  }

  enableAttributes() {
    this.attributes.forEach((attribute) => {
      this.glContext.enableVertexAttribArray(attribute.getLocation());
    });
  }

  getAttribute(name: string) {
    return this.attributes.get(name);
  }

  getAttributes() {
    return this.attributes;
  }

  setUniform(name: string, value: number) {
    this.glContext.uniform1f(this.uniforms.get(name).getLocation(), value);
  }

  setUniformVec2(name: string, value: Iterable<number>) {
    this.glContext.uniform2fv(this.uniforms.get(name).getLocation(), value);
  }

  setUniformVec3(name: string, value: Iterable<number>) {
    this.glContext.uniform3fv(this.uniforms.get(name).getLocation(), value);
  }

  setUniformVec4(name: string, value: Iterable<number>) {
    this.glContext.uniform4fv(this.uniforms.get(name).getLocation(), value);
  }

  setUniformMat3(name: string, value: Iterable<number>) {
    this.glContext.uniformMatrix3fv(
      this.uniforms.get(name).getLocation(),
      false,
      value
    );
  }

  setUniformMat4(name: string, value: Iterable<number>) {
    this.glContext.uniformMatrix4fv(
      this.uniforms.get(name).getLocation(),
      false,
      value
    );
  }

  setUniformSampler(texture: Texture, name: string, textureUnit: number) {
    this.glContext.activeTexture(this.glContext.TEXTURE0 + textureUnit);
    texture.bind();
    this.glContext.uniform1i(
      this.uniforms.get(name).getLocation(),
      textureUnit
    );
  }

  use() {
    this.glContext.useProgram(this.shaderProgram);
    this.enableAttributes();
  }
}

class ShaderAttribute {
  name: WebGLActiveInfo['name'];
  size: WebGLActiveInfo['size'];
  type: WebGLActiveInfo['type'];
  location: WebGLUniformLocation;
  constructor(
    name: string,
    size: number,
    type: number,
    location: WebGLUniformLocation
  ) {
    this.name = name;
    this.size = size;
    this.type = type;
    this.location = location;
  }

  getName() {
    return this.name;
  }

  getSize() {
    return this.size;
  }

  getType() {
    return this.type;
  }

  getLocation() {
    return this.location;
  }
}

class ShaderUniform {
  name: WebGLActiveInfo['name'];
  size: WebGLActiveInfo['size'];
  type: WebGLActiveInfo['type'];
  location: WebGLUniformLocation;
  constructor(
    name: string,
    size: number,
    type: number,
    location: WebGLUniformLocation
  ) {
    this.name = name;
    this.size = size;
    this.type = type;
    this.location = location;
  }

  getName() {
    return this.name;
  }

  getSize() {
    return this.size;
  }

  getType() {
    return this.type;
  }

  getLocation() {
    return this.location;
  }
}

export class VertexBuffer {
  bufferType: GLenum;
  buffer: any;
  bufferLength: number;
  usage: number;
  shader: any;
  attributeMappings: Map<any, any>;
  stride: number;
  glContext: WebGLRenderingContext;
  constructor(
    type: GLenum,
    usage: number,
    glContext: WebGLRenderingContext & { canvas: HTMLCanvasElement }
  ) {
    this.bufferType = type;
    this.buffer = null;
    this.bufferLength = 0;
    this.usage = usage;
    this.shader = null;
    this.attributeMappings = new Map();
    this.stride = 0;
    this.glContext = glContext;

    if (!this.buffer) {
      this.buffer = this.glContext.createBuffer();
    }
  }

  setBufferData(dataArray: number[], bufferLength = 0) {
    this.bufferLength = bufferLength;
    if (this.bufferLength == 0) {
      this.bufferLength = dataArray.length;
    }

    if (this.bufferType == this.glContext.ELEMENT_ARRAY_BUFFER) {
      this.glContext.bufferData(
        this.bufferType,
        new Uint16Array(dataArray),
        this.usage
      );
    } else {
      this.glContext.bufferData(
        this.bufferType,
        new Float32Array(dataArray),
        this.usage
      );
    }
  }

  bind(shader: WebGLShader) {
    this.shader = shader;
    this.glContext.bindBuffer(this.bufferType, this.buffer);

    if (this.bufferType == this.glContext.ARRAY_BUFFER) {
      let offset = 0;

      // console.log(
      //   'this.attributeMappings.size: ' + this.attributeMappings.size
      // );

      this.attributeMappings.forEach((numberOfComponents, attributeName) => {
        // console.log('attrib: ' + attributeName);
        // console.log('number of components: ' + numberOfComponents);
        // console.log(
        //   'this.stride: ' +
        //     this.stride +
        //     ' bytes ' +
        //     Float32Array.BYTES_PER_ELEMENT
        // );
        // console.log('offset: ' + offset);
        this.glContext.vertexAttribPointer(
          this.shader.getAttribute(attributeName).getLocation(),
          numberOfComponents,
          this.glContext.FLOAT,
          false,
          this.stride * Float32Array.BYTES_PER_ELEMENT,
          offset * Float32Array.BYTES_PER_ELEMENT
        );

        offset += numberOfComponents;
      }, this);
    }
  }

  mapAttribute(attributeName: string, numberOfComponents: number) {
    this.attributeMappings.set(attributeName, numberOfComponents);
    this.stride = 0;

    this.attributeMappings.forEach((numberOfComponents) => {
      this.stride += numberOfComponents;
    }, this);
  }

  drawTriangles() {
    if (this.bufferType == this.glContext.ELEMENT_ARRAY_BUFFER) {
      this.glContext.drawElements(
        this.glContext.TRIANGLES,
        this.bufferLength,
        this.glContext.UNSIGNED_SHORT,
        0
      );
    } else {
      this.glContext.drawArrays(
        this.glContext.TRIANGLES,
        0,
        this.bufferLength / this.stride
      );
    }
  }
}

export class Texture {
  texture: WebGLTexture | null;
  type: number;
  mipmapsEnabled: boolean;
  glContext: WebGLRenderingContext;
  constructor(
    textureType: number,
    glContext: WebGLRenderingContext & { canvas: HTMLCanvasElement }
  ) {
    this.glContext = glContext;
    this.texture = glContext.createTexture();
    this.type = textureType;
    this.mipmapsEnabled = true;
    this.bind();
    this.setFiltering(
      this.glContext.LINEAR_MIPMAP_LINEAR,
      this.glContext.LINEAR
    );
    this.release();
  }

  bind() {
    this.glContext.bindTexture(this.type, this.texture);
  }

  release() {
    this.glContext.bindTexture(this.type, null);
  }

  setFiltering(minFilter: number, magFilter: number) {
    this.glContext.texParameteri(
      this.type,
      this.glContext.TEXTURE_MIN_FILTER,
      minFilter
    );
    this.glContext.texParameteri(
      this.type,
      this.glContext.TEXTURE_MAG_FILTER,
      magFilter
    );
  }

  setWrapping(horizontal: number, vertical: number) {
    this.glContext.texParameteri(
      this.type,
      this.glContext.TEXTURE_WRAP_S,
      horizontal
    );
    this.glContext.texParameteri(
      this.type,
      this.glContext.TEXTURE_WRAP_T,
      vertical
    );
  }

  toggleAutoMipmaps(status: boolean) {
    this.mipmapsEnabled = status;
  }

  generateMipmap() {
    if (this.mipmapsEnabled) {
      this.glContext.generateMipmap(this.type);
    }
  }

  loadImage(url: string, target = this.glContext.TEXTURE_2D) {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      this.glContext.texImage2D(
        target,
        0,
        this.glContext.RGBA,
        this.glContext.RGBA,
        this.glContext.UNSIGNED_BYTE,
        img
      );
      this.generateMipmap();
    };
    img.src = url;
  }

  setImage(
    target: number,
    mipmapLevel: number,
    width: number,
    height: number,
    pixelFormat: number,
    pixelDataType: number,
    image: ArrayBufferView | null
  ) {
    this.glContext.texImage2D(
      target,
      mipmapLevel,
      pixelFormat,
      width,
      height,
      0,
      pixelFormat,
      pixelDataType,
      image
    );
    this.generateMipmap();
  }

  setVideo(
    target: number,
    mipmapLevel: number,
    pixelFormat: number,
    pixelDataType: number,
    video: TexImageSource
  ) {
    this.glContext.texImage2D(
      target,
      mipmapLevel,
      pixelFormat,
      pixelFormat,
      pixelDataType,
      video
    );
    this.generateMipmap();
  }

  setSubImage(
    target: number,
    mipmapLevel: number,
    xOffset: number,
    yOffset: number,
    width: number,
    height: number,
    pixelFormat: number,
    pixelDataType: number,
    image: ArrayBufferView | null
  ) {
    this.glContext.texSubImage2D(
      target,
      mipmapLevel,
      xOffset,
      yOffset,
      width,
      height,
      pixelFormat,
      pixelDataType,
      image
    );
    this.generateMipmap();
  }

  getTexture() {
    return this.texture;
  }
}

export class FrameBuffer {
  glContext: WebGLRenderingContext;
  frameBuffer: WebGLFramebuffer | null;
  width: number;
  height: number;
  colorBuffer: Texture;
  depthStencilBuffer: WebGLBuffer | null;
  constructor(
    type: number,
    width: number,
    height: number,
    pixelFormat: number,
    pixelDataType: number,
    hasDepth: boolean,
    hasStencil: boolean,
    glContext: WebGLRenderingContext & { canvas: HTMLCanvasElement }
  ) {
    this.glContext = glContext;
    this.frameBuffer = glContext.createFramebuffer();
    this.width = width;
    this.height = height;

    this.colorBuffer = new Texture(type, glContext);
    this.colorBuffer.toggleAutoMipmaps(false);
    this.colorBuffer.bind();
    this.colorBuffer.setFiltering(glContext.LINEAR, glContext.LINEAR);

    this.bind();

    if (type == glContext.TEXTURE_2D) {
      this.colorBuffer.setImage(
        glContext.TEXTURE_2D,
        0,
        width,
        height,
        pixelFormat,
        pixelDataType,
        null
      );
      glContext.framebufferTexture2D(
        glContext.FRAMEBUFFER,
        glContext.COLOR_ATTACHMENT0,
        glContext.TEXTURE_2D,
        this.colorBuffer.getTexture(),
        0
      );
    } else {
      for (let i = 0; i < 6; i++) {
        this.colorBuffer.setImage(
          glContext.TEXTURE_CUBE_MAP_POSITIVE_X + i,
          0,
          width,
          height,
          pixelFormat,
          pixelDataType,
          null
        );
        glContext.framebufferTexture2D(
          glContext.FRAMEBUFFER,
          glContext.COLOR_ATTACHMENT0,
          glContext.TEXTURE_CUBE_MAP_POSITIVE_X + i,
          this.colorBuffer.getTexture(),
          0
        );
      }
    }

    this.depthStencilBuffer = null;

    if (hasDepth && hasStencil) {
      this.depthStencilBuffer = glContext.createRenderbuffer();
      glContext.bindRenderbuffer(
        glContext.RENDERBUFFER,
        this.depthStencilBuffer
      );
      glContext.renderbufferStorage(
        glContext.RENDERBUFFER,
        glContext.DEPTH_STENCIL,
        width,
        height
      );
      glContext.framebufferRenderbuffer(
        glContext.FRAMEBUFFER,
        glContext.DEPTH_STENCIL_ATTACHMENT,
        glContext.RENDERBUFFER,
        this.depthStencilBuffer
      );
    } else if (hasDepth && !hasStencil) {
      this.depthStencilBuffer = glContext.createRenderbuffer();
      glContext.bindRenderbuffer(
        glContext.RENDERBUFFER,
        this.depthStencilBuffer
      );
      glContext.renderbufferStorage(
        glContext.RENDERBUFFER,
        glContext.DEPTH_COMPONENT16,
        width,
        height
      );
      glContext.framebufferRenderbuffer(
        glContext.FRAMEBUFFER,
        glContext.DEPTH_ATTACHMENT,
        glContext.RENDERBUFFER,
        this.depthStencilBuffer
      );
    } else if (!hasDepth && hasStencil) {
      this.depthStencilBuffer = glContext.createRenderbuffer();
      glContext.bindRenderbuffer(
        glContext.RENDERBUFFER,
        this.depthStencilBuffer
      );
      glContext.renderbufferStorage(
        glContext.RENDERBUFFER,
        glContext.STENCIL_INDEX8,
        width,
        height
      );
      glContext.framebufferRenderbuffer(
        glContext.FRAMEBUFFER,
        glContext.STENCIL_ATTACHMENT,
        glContext.RENDERBUFFER,
        this.depthStencilBuffer
      );
    }

    this.colorBuffer.release();
    this.release();
  }

  bind(cubemapSurface?: GLenum) {
    this.glContext.bindFramebuffer(
      this.glContext.FRAMEBUFFER,
      this.frameBuffer
    );

    if (cubemapSurface != null) {
      this.glContext.framebufferTexture2D(
        this.glContext.FRAMEBUFFER,
        this.glContext.COLOR_ATTACHMENT0,
        cubemapSurface,
        this.colorBuffer.getTexture(),
        0
      );
    }

    this.glContext.viewport(0, 0, this.width, this.height);
  }

  release() {
    this.glContext.bindFramebuffer(this.glContext.FRAMEBUFFER, null);
    this.glContext.viewport(
      0,
      0,
      this.glContext.canvas.width,
      this.glContext.canvas.height
    );
  }

  getColorBuffer() {
    return this.colorBuffer;
  }
}
