import type { State } from '@pin/schemas/client-state-response';
import { isFuture } from 'date-fns';
import { pushAria } from './ariaLive';

export const getariaLiveForState = (state: State) => {
  if (state.type === 'before') {
    return 'Avstemningen har ikke åpnet enda';
  }
  if (state.type === 'open') {
    return 'Avstemningen har åpnet';
  }
  if (state.type === 'closing' && isFuture(new Date(state.endTime))) {
    return 'Avstemningen stenger snart';
  }
  if (state.type === 'closing' || state.type === 'after') {
    return 'Avstemningen er avsluttet';
  }
  return '';
};

let previousStateText = '';
let previousStateId = '';

export const readClientStateAriaLive = (state: State | undefined) => {
  if (state == undefined || state.id === 'not-received') {
    return;
  }
  const text = getariaLiveForState(state);
  if (previousStateText === '' && previousStateId == '') {
    previousStateText = text;
    previousStateId = state.id;
    return;
  }
  if (previousStateText !== text || previousStateId !== state.id) {
    if (text !== '') {
      pushAria(text);
    }
  }
  previousStateText = text;
  previousStateId = state.id;
};
