<script lang="ts">
  import RadioInput from './RadioInput.svelte';
  import Angryface from './Smileys/Angryface.svelte';
  import Neutralface from './Smileys/Neutralface.svelte';
  import Partyingface from './Smileys/Partyingface.svelte';
  import Pensiveface from './Smileys/Pensiveface.svelte';
  import Smiling from './Smileys/Smiling.svelte';

  export let group: number;
  export let comment = '';
  export let onSubmit: () => void;

  const values = [
    {
      emoji: Angryface,
      text: 'Veldig dårlig',
      value: 0,
    },
    {
      emoji: Pensiveface,
      value: 1,
      text: 'Dårlig',
    },
    {
      emoji: Neutralface,
      text: 'Greit',
      value: 2,
    },
    {
      emoji: Smiling,
      text: 'Bra',
      value: 3,
    },
    {
      emoji: Partyingface,
      text: 'Veldig bra',
      value: 4,
    },
  ] as const;
</script>

<form on:submit|preventDefault={onSubmit}>
  <fieldset class="emojiwrapper">
    <legend tabindex="-1">Hva syns du om denne måten å stemme på?</legend>
    {#each values as radioValue (radioValue.value)}
      <RadioInput
        value={radioValue.value}
        bind:group
        name="feedback-interaction"
        text={radioValue.text}
        selected={radioValue.value === group}
      >
        <svelte:component this={radioValue.emoji} />
      </RadioInput>
    {/each}
  </fieldset>

  <label class="header-style" for="comment">
    Er det noe mer du vil legge til?
  </label>
  <textarea
    id="comment"
    name="comment"
    maxlength="1000"
    placeholder="Skriv her..."
    class="comment"
    bind:value={comment}
  ></textarea>
  <slot />
</form>

<style>
  textarea {
    width: 90%;
    height: 8rem;
    margin: var(--spacing-16);
    padding: var(--spacing-8);
    border: 1px solid var(--color-button-secondary-text);
  }

  legend {
    margin: var(--spacing-8);
    box-shadow: none;
  }

  label {
    margin: var(--spacing-16) 0;
    font-size: var(--font-size-m);
  }

  textarea {
    color: var(--color-text);
    background-color: var(--color-bg);
  }

  .emojiwrapper {
    margin: var(--spacing-24) 0;
    display: flex;
    justify-content: center;
    border: none;
  }
</style>
