import { trackEvent } from './snowplow';

let clickCount: number = 0;

let debouncedTrackEvent: number | undefined = undefined;
const trackClickDebounced = () => {
  clickCount++;
  clearTimeout(debouncedTrackEvent);
  debouncedTrackEvent = window.setTimeout(() => {
    trackEvent({
      action: 'click:star',
      label: `Number of clicks: ${clickCount}`,
    });
  }, 3000);
};

export default trackClickDebounced;
