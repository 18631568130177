export const laat_for_laat_url =
  'https://www.nrk.no/anmeldelser/anmeldelse_-stjernekamp-2025-_-episode-4-1.17368824';

type Promo = {
  title: string;
  description: string;
  href: string;
  img: string;
  contentId: string;
  videoSrc?: string;
  pillText: string;
};

export const promos: Array<Promo> = [
  {
    title: 'Se din favoritt om igjen',
    description: 'Er du enig med anmelderen?',
    href: laat_for_laat_url,
    img: 'https://gfx.nrk.no/V-3ABf1PpbSFPk6rNAn1gACOwzTrQpuCRhXQee-wTeaQ',
    contentId: 'anmeldelser',
    pillText: 'Les på NRK.no',
  },
  {
    title: 'Kake er i vinden ',
    description: '- bokstavelig talt',
    img: 'https://gfx.nrk.no/nB5kg68GZ2bIiPCEO6asCwcz2phFicW3Jw-qD88l7T7A',
    href: 'https://tv.nrk.no/serie/bakemesterskapet',
    contentId: 'bakemesterskapet',
    pillText: 'Se i NRK TV',
  },
  {
    title: 'Ny sesong kommer 2. april',
    description: 'Cammo',
    img: 'https://gfx.nrk.no/D_m_XSZc4zKjFpzZWKpUmgmF9mumgL1QADCd2iqrk_Dw',
    href: 'https://tv.nrk.no/serie/cammo',
    contentId: 'cammo',
    videoSrc: 'nrk:program:KMNO10004525',
    pillText: 'Se i NRK TV',
  },
];

export default promos;
