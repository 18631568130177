import './styles/colors.css';
import './styles/main.css';
import './styles/stjernekamp.css';

import './login/login';
import './util/polyfill';
import './util/vhcalculation';

import { mount } from 'svelte';
import App from './App.svelte';
import { disableHoverOnTouch } from './util/disableHover';
import initializePinsight from './util/pinsight';
import { initializeSentry } from './util/sentry';
import { initializeSnowPlowTracker } from './util/snowplow';
import { initializeVersionCheck } from './util/versionCheck';

initializeSentry();
initializeSnowPlowTracker();
initializePinsight();
initializeVersionCheck();
disableHoverOnTouch();

const initializeApp = () => {
  return mount(App, {
    target: document.getElementById('app')!,
  });
};

const app = initializeApp();
export default app;
