import { clientStateObservable } from '@pin/client-state-observable';
import { shareReplay } from 'rxjs';
import { trackEvent } from './util/snowplow';

export const clientState = clientStateObservable({
  apiUrl: import.meta.env.VITE_CLIENT_API_URL,
  clientId: 'stjernekamp',
  onError: () => {
    trackEvent({ action: 'error:fetching-clientstate' });
  },
}).pipe(shareReplay({ refCount: false, bufferSize: 1 }));
