<script lang="ts">
  import type { AfterState } from '@pin/schemas/client-state-response';
  import { onMount } from 'svelte';
  import CouldNotVote from '../components/CouldNotVote.svelte';
  import Promo from '../components/Promo/Promo.svelte';
  import { trackEvent } from '../util/snowplow';
  import { sentVotes } from '../util/votesStore';
  export let pollData: AfterState;

  $: didVote = $sentVotes != null;

  onMount(() => {
    trackEvent(
      { action: 'state:after-poll', label: pollData.title },
      pollData.id
    );
  });
</script>

<!-- svelte-ignore a11y-autofocus -->
<div class="wrapper">
  <h2 autofocus tabindex="-1">Avstemningen er avsluttet</h2>
  {#if didVote}
    <p>Takk for at du deltok</p>
  {:else}
    <CouldNotVote endTime={pollData.endTime} startTime={pollData.startTime} />
  {/if}
</div>
<Promo />

<style>
  .wrapper {
    margin: 1rem 0 2rem;
    text-align: center;
  }
</style>
