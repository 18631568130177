<script lang="ts">
  import FeedbackDialog from './FeedbackDialog.svelte';
  import type CoreDialog from '../core/CoreDialog.svelte';
  import { trackEvent } from '../../util/snowplow';

  let feedbackDialog: CoreDialog;

  const onClick = () => {
    trackEvent({ action: 'click:feedback-open' });
    feedbackDialog.show();
  };
</script>

<button on:click={onClick}>
  <span> Gi oss din tilbakemelding </span>
</button>
<FeedbackDialog bind:feedbackDialog />

<style>
  button {
    margin-top: var(--spacing-32);
    color: var(--color-text);
    background-color: transparent;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 950;
    line-height: 1.25rem; /* 166.667% */
    letter-spacing: 0.0825rem;
  }

  button span {
    border-bottom: 2px solid var(--sk-pink, var(--color-button-secondary-bg));
  }
</style>
