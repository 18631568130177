import { get, writable, type Updater } from 'svelte/store';
import { getItem, setItem } from './storage';

export const createCachedStore = <V>(
  storageType: 'local' | 'session',
  key: string,
  initialValue?: V
) => {
  const {
    subscribe,
    set: storeSet,
    update: storeUpdate,
  } = writable<V>(getItem(storageType, key, initialValue));

  const set = (newValue: V) => {
    storeSet(newValue);
    setItem(storageType, key, newValue);
  };
  const update = (updater: Updater<V>) => {
    storeUpdate(updater);
    setItem(storageType, key, get({ subscribe }));
  };

  return { subscribe, update, set };
};
