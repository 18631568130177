import { derived, get, writable, type Readable } from 'svelte/store';
import { clientState } from '../clientState';
import { getItem, setItem } from './storage';
import { loginModelStore } from '../login/loginModel';

const userId = writable('');
loginModelStore.subscribe((loginModel) => {
  userId.set(
    loginModel?.session?.type === 'authenticated' ? loginModel.session.sub : ''
  );
});

const pollId = writable('');
clientState.subscribe((c) => {
  pollId.set(c.id);
});

const votesCartKey = derived([pollId, userId], ([$pollId, $userId]) => {
  if ($pollId == null || $userId == null) return null;
  return `${$pollId}-${$userId}-stored-votes`;
});
const sentVotesKey = derived([pollId, userId], ([$pollId, $userId]) => {
  if (!$pollId || !$userId) return null;
  return `${$pollId}-${$userId}-sent-votes`;
});

const createPersistentStore = (
  storageType: 'local' | 'session',
  keyStore: Readable<string | null>
) => {
  const store = writable<string[] | null>(null);

  keyStore.subscribe((key) => {
    if (key == null) return;
    store.set(getItem(storageType, key, null));
  });

  store.subscribe((value) => {
    const key = get(keyStore);
    if (key == null || value == null) return;
    setItem(storageType, key, value);
  });

  return store;
};

export const sentVotes = createPersistentStore('local', sentVotesKey);

export const votesCart = createPersistentStore('session', votesCartKey);
