<script lang="ts" context="module">
</script>

<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import NRKDialog from '@nrk/core-dialog'; // Using NPM
  if (window.customElements.get('pin-dialog') === undefined) {
    window.customElements.define('pin-dialog', NRKDialog);
  }
  interface PinDialog extends HTMLElement {
    show: () => void;
    close: () => void;
  }

  let dialogRef: PinDialog;

  export let id: string;
  export let label: string;

  export function show() {
    dialogRef.show();
  }

  export function close() {
    dialogRef.close();
  }

  interface DialogToggleEvent extends Event {
    target: EventTarget & { hidden: boolean };
  }

  const dispatch = createEventDispatcher();

  const handleToggle = (event: DialogToggleEvent) => {
    if (event.target.hidden) {
      dispatch('close');
    } else {
      dispatch('open');
    }
  };

  onMount(() => {
    dialogRef.addEventListener('dialog.toggle', handleToggle as EventListener);
  });

  onDestroy(() => {
    dialogRef.removeEventListener(
      'dialog.toggle',
      handleToggle as EventListener
    );
  });
</script>

<div id={`${id}-backdrop`} class="backdrop"></div>
<pin-dialog
  {id}
  bind:this={dialogRef}
  class="pin-dialog"
  hidden
  backdrop={`${id}-backdrop`}
  aria-label={label}
>
  <div class="content">
    <slot />
  </div>
</pin-dialog>

<style>
  .pin-dialog {
    position: fixed;
    margin: auto;
    top: 2rem;
    left: 0;
    right: 0;
    border: 0;
    width: 100%;
    max-width: 30rem;
    max-height: 100%;
    overflow-y: auto;
    transition: 0.2s;
    color: var(--color-cart-text);
    background: hsla(221, 90%, 8%, 0.8);
    border: 1px solid rgba(42, 60, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .backdrop {
    background: var(--color-bg);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    opacity: 0.7;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.2s;
  }
</style>
