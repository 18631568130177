<script lang="ts">
  import { onMount } from 'svelte';

  export let elementType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';
  export let styling: string | undefined = undefined;
  let elementRef: HTMLHeadingElement | HTMLParagraphElement;

  /* set focus on element for screen-readers */
  onMount(() => {
    elementRef?.focus({ preventScroll: true });
  });
</script>

<svelte:element
  this={elementType}
  class="element"
  tabindex="-1"
  bind:this={elementRef}
  style={styling}
>
  <slot />
</svelte:element>

<style>
  .element {
    box-shadow: none;
  }
</style>
