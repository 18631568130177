import {
  HttpError,
  parseRetryAfterHeader
} from "./chunk-2RSHMDIU.js";

// src/rxjs.ts
import { retry, timer } from "rxjs";
function jsonSuccessSelector(response) {
  if (!response.ok) {
    throw new HttpError(response);
  }
  return response.json();
}
function retryFetch(count) {
  return retry({
    count,
    delay: (error, retryCount) => {
      const exponentialBackoff = 1e3 * retryCount ** 2;
      if (error instanceof HttpError) {
        return timer(
          Math.max(
            parseRetryAfterHeader(error.response) ?? 0,
            exponentialBackoff
          )
        );
      }
      return timer(exponentialBackoff);
    },
    resetOnSuccess: true
  });
}
export {
  jsonSuccessSelector,
  retryFetch
};
