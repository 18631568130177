export const ignoreErrors = [
  'Importing a module script failed',
  'Abort due to cancellation of share',
  'No collector configured',
  'Invalid call to runtime.sendMessage(). Tab not found.', // extension
  "undefined is not an object (evaluating 'window.webkit.messageHandlers')", // not our code
  "Can't find variable: logMutedMessage", // edge ios when battery saver is on
  'Load failed',
  'Request timeout getDictionariesByLanguageId',
  'Request timeout getSupportScreenShot',
  'Request timeout isDictateAvailable',
  'Request timeout isPredictionAvailable',
  'Request timeout dictionariesDistributor.getValue',
  'Request timeout speechVoicesDistributor.getValue',
  'Request timeout userDistributor.getValue',
  'Request timeout predictionDistributor.getValue',
  'Request timeout dictateStateDistributor.getValue',
  'Request timeout nn-NO_wordsDistributor.getValue',
  'Request timeout availableTextCheckLanguagesDistributor.getValue',
  'Request timeout es_wordsDistributor.getValue',
  'Request timeout lettersVoicesDistributor.getValue',
  'Request timeout topicsDistributor.getValue',
  'Request timeout availableLanguagesDistributor.getValue',
  'Request timeout ac_wordsDistributor.getValue',
  'Request timeout nb-NO_wordsDistributor.getValue',
  'Request timeout ua_wordsDistributor.getValue',
  'Request timeout en_wordsDistributor.getValue',
  'Request timeout appSettingsDistributor.getValue',
  'Request timeout DefineExpirationForLanguagePacks.getValue',
  'Request timeout textCheckersDistributor.getValue',
  'Request timeout de_wordsDistributor.getValue',
  'Request timeout fr_wordsDistributor.getValue',
  'Request timeout ru_wordsDistributor.getValue',
];
