<script lang="ts">
  import type { Alternative } from '@pin/schemas/client-state-response';

  import { nrkClose } from '@nrk/core-icons';

  export let alternative: Alternative | undefined;
  export let removeVote: () => void;
  export let disabled: boolean;
</script>

<button
  class="remove-vote"
  on:click={removeVote}
  aria-label={alternative
    ? `Du har valgt ${alternative?.title}.`
    : 'Ikke utfylt valg'}
  title="Trykk for å fjerne."
  disabled={!alternative || disabled}
>
  {#if !!alternative}
    <picture>
      <source srcset={alternative.image.src} type="image/webp" />
      <source srcset={alternative.image.fallbackSrc} type="image/jpg" />
      <img
        src="{alternative.image.fallbackSrc}.jpg"
        alt={alternative.image.alt}
      />
    </picture>
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <div class="remove-prefix">{@html nrkClose}</div>
  {/if}
</button>

<style>
  .remove-vote {
    padding: 0;
    transition: none;
    position: relative;
    border-radius: 9999px;
    background: transparent;
    color: var(--color-text);
    width: 2.5rem;
    height: 2.5rem;
  }

  .remove-vote:active {
    transform: none;
  }

  .remove-vote:disabled {
    cursor: unset;
  }

  .remove-prefix {
    position: absolute;
    top: 0;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 9999px;
    color: var(--color-cart-remove-text);
    background-color: var(--color-cart-remove-bg);
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    animation: scaleIn 0.2s ease-in-out forwards 0.05s;
    transform: scale(0);
    transform-origin: bottom left;
  }

  .remove-prefix:disabled {
    filter: brightness(0.5) opacity(0.7);
  }

  @keyframes scaleIn {
    from {
      transform: translate(1.75rem, -0.6rem) scale(0);
    }
    to {
      transform: translate(1.75rem, -0.6rem) scale(1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 9999px;
    font-size: var(--font-size-xs);
  }
</style>
