<script lang="ts">
  import { nrkLogoNrk } from '@nrk/core-icons/logo';
</script>

<footer id="footer">
  <a href="https://www.nrk.no/" class="logo" aria-label="NRK">
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkLogoNrk}
  </a>
  <a
    href="https://www.nrk.no/etikk/opphavsrett-pa-nrk.no-1.2843522"
    class="copyright"
  >
    Opphavsrett NRK © {new Date().getFullYear()}
  </a>
  <a
    href="https://www.nrk.no/etikk/redaktoransvar-pa-nrk.no-1.2843526"
    class="editors"
  >
    <span>Ansvarlig redaktør: Vibeke Fürst Haugen</span>
    <span>Nettsjef: Kathrine Hammerstad (Konst.)</span>
  </a>
</footer>

<style>
  footer {
    /* The bottom padding of the footer is changed depending on if the shopping-cart is in the DOM or not (See ShoppingCart.svelte)*/
    padding: var(--spacing-16);
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .logo {
    font-size: 1.5rem;
  }

  .copyright {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .editors span {
    display: block;
    font-weight: 290;
    font-size: var(--font-size-xs);
    line-height: 1.66667;
    letter-spacing: 1%;
  }

  @media (min-width: 50rem) {
    .editors span {
      display: inline-block;
      margin-right: var(--spacing-8);
    }
  }
</style>
