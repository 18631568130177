<script lang="ts">
</script>

<h1>
  <picture>
    <source srcset="logo.webp" type="image/webp" />
    <source srcset="logo.png" type="image/png" />
    <img
      class="logo"
      src="logo.png"
      width={362}
      height={320}
      alt="Logo for Stjernekamp"
    />
  </picture>
</h1>

<style>
  :global(#index-logo) {
    display: none !important;
  }
  img {
    margin-top: -1rem;
    margin-bottom: 1rem;
    filter: drop-shadow(0px 0px 8px var(--sk-black));
  }

  h1 {
    padding: 0;
  }

  .logo {
    max-height: 20rem;
  }
</style>
