<script lang="ts">
  import { onMount } from 'svelte';
  import { trackEvent } from '../util/snowplow';

  onMount(() => {
    trackEvent({ action: 'state:empty-poll' });
  });
</script>

<article>
  <!-- svelte-ignore a11y-autofocus -->
  <h2 autofocus tabindex="-1">Ingen avstemning funnet</h2>
  <p>
    Her fant vi ingen avstemning, er du sikker på at du er på riktig sted? Hvis
    ja kan du prøve å oppdatere siden, eller gå til
  </p>
  <a href="https://delta.nrk.no">delta.nrk.no</a>
</article>

<style>
  article {
    text-align: center;
    max-width: 30rem;
  }
  a {
    display: inline;
    text-decoration: underline;
  }
</style>
