<script lang="ts">
  import { emptyStateId } from '@pin/client-state-observable';
  import type { State } from '@pin/schemas/client-state-response';
  import { isFuture } from 'date-fns';
  import { clientState } from './clientState';
  import AriaLive from './components/AriaLive.svelte';
  import Background from './components/Background.svelte';
  import Confetti from './components/Confetti.svelte';
  import CookieBanner from './components/CookieBanner.svelte';
  import FeedbackButton from './components/Feedback/FeedbackButton.svelte';
  import Footer from './components/Footer.svelte';
  import Loader from './components/Loader.svelte';
  import Logo from './components/Logo.svelte';
  import HeaderLink from './login/HeaderLink.svelte';
  import { loginModelStore } from './login/loginModel';
  import { readClientStateAriaLive } from './util/clientStateAriaLive';
  import { trackPageView } from './util/snowplow';
  import After from './views/After.svelte';
  import Before from './views/Before.svelte';
  import Closed from './views/Closed.svelte';
  import Empty from './views/Empty.svelte';
  import Login from './views/Login.svelte';
  import Open from './views/Open.svelte';
  import Poster from './views/Poster.svelte';

  const checkOpen = (clientState: State | undefined) =>
    clientState?.type == 'open' ||
    (clientState?.type === 'closing' &&
      isFuture(new Date(clientState.endTime)));

  let isOpen = $derived(checkOpen($clientState));

  $effect(() => {
    readClientStateAriaLive($clientState);
  });

  $effect(() => {
    if ($loginModelStore?.session != null) {
      trackPageView();
    }
  });
</script>

<CookieBanner />
<HeaderLink />
<Background />
<main>
  <Logo />
  <AriaLive mode="assertive" />
  {#if $clientState == null}
    <Empty />
  {:else if $clientState.id === emptyStateId || $loginModelStore?.session == null}
    <Loader description="Laster avstemning" />
  {:else if $clientState.type === 'closing' && !isOpen}
    <Closed pollData={$clientState} />
  {:else if $clientState.type === 'after'}
    <After pollData={$clientState} />
  {:else if $clientState.type === 'poster'}
    <Poster pollData={$clientState} />
  {:else if $loginModelStore.session.type === 'unauthenticated'}
    <Login />
  {:else if $clientState.type === 'before'}
    <Before pollData={$clientState} />
  {:else if $clientState.type === 'open' || $clientState.type === 'closing'}
    <Open pollData={$clientState} />
  {/if}

  <FeedbackButton />
</main>
<Footer />
<Confetti />

<style>
  main {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 var(--spacing-16) var(--spacing-32);
  }
</style>
