<script lang="ts">
  import type { PosterState } from '@pin/schemas/client-state-response';
  import { onMount } from 'svelte';
  import { trackEvent } from '../util/snowplow';
  import Promo from '../components/Promo/Promo.svelte';
  export let pollData: PosterState;

  onMount(() => {
    trackEvent({ action: 'state:poster' });
  });
</script>

<!-- svelte-ignore a11y-autofocus -->
<h2 autofocus tabindex="-1">{pollData.title}</h2>
<p>{pollData.description}</p>

{#if pollData.level === 'info'}
  <Promo />
{/if}

<style>
  p {
    margin-bottom: 2rem;
    text-align: center;
  }
</style>
