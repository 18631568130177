// src/userPrefersReducedMotion.ts
var userPrefersReducedMotion = () => {
  const match = window.matchMedia && window.matchMedia("(prefers-reduced-motion: reduce)");
  return match && match.matches;
};

// src/autoplayvideo.ts
var shouldAutoPlay = false;
var hasCheckedAutoPlay = false;
var checkAndSetAutoPlay = () => {
  try {
    hasCheckedAutoPlay = true;
    if (userPrefersReducedMotion()) {
      shouldAutoPlay = false;
      return shouldAutoPlay;
    }
    shouldAutoPlay = true;
    return shouldAutoPlay;
  } catch (_error) {
    return shouldAutoPlay;
  }
};
var checkAutoplay = () => {
  if (hasCheckedAutoPlay) {
    return shouldAutoPlay;
  }
  return checkAndSetAutoPlay();
};
export {
  checkAutoplay,
  userPrefersReducedMotion
};
