import { getWeek, getYear } from 'date-fns';
import { jwtDecode } from 'jwt-decode';
import { get } from 'svelte/store';
import { loginModelStore } from '../login/loginModel';
import { captureError, captureHttpError } from '../util/sentry';
import { getSessionId } from '../util/sessionId';
import { postFeedbackV2 } from './feedbackV2';

export function getAppId() {
  const today = new Date();
  return `stjernekamp-${getYear(today)}-${getWeek(today)}`;
}

const getUserData = async () => {
  try {
    const getAccessToken = get(loginModelStore)?.getAccessToken;
    const token = await getAccessToken?.();
    const decoded = jwtDecode(token ?? '') as {
      'nrk/age'?: string;
      'nrk/user/vl'?: number;
    };
    return {
      age: decoded['nrk/age'],
      verification_level: decoded['nrk/user/vl'],
      sessionId: getSessionId(),
    };
  } catch {
    return {
      sessionId: getSessionId(),
    };
  }
};

type Feedback = {
  comment?: string | undefined;
  extraData?:
    | {
        category?: string;
        votesFeedback?: 0 | 1 | -1 | 2 | 3 | 4 | undefined;
        age?: string | undefined;
        verification_level?: number | undefined;
        sessionId: string;
      }
    | undefined;
  fid: string;
  userAgent: string;
  appid: string;
};

export async function postFeedback(
  {
    comment,
    votesFeedback,
    email,
    category,
  }: {
    comment?: string;
    votesFeedback?: number;
    email?: string;
    category?: string;
  },
  { fid }: { fid: string | null }
) {
  try {
    const userData = await getUserData();
    const result = await fetch(
      `${import.meta.env.VITE_FEEDBACK_API_URL}/api/v2/feedback/${getAppId()}${
        fid ? `/${fid}` : ''
      }`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: fid ? 'PATCH' : 'POST',
        body: JSON.stringify({
          comment,
          extraData: {
            category,
            email,
            votesFeedback,
            ...userData,
          },
        }),
      }
    );
    if (result.ok) {
      const resultJson: Feedback = await result.json();
      void postFeedbackV2({ comment, votesFeedback, email, category }, fid);
      return resultJson;
    }

    captureHttpError('posting feedback failed', result);
    return null;
  } catch (error) {
    captureError('posting feedback failed', error);
    return null;
  }
}
