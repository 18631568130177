<script lang="ts">
  import { fade } from 'svelte/transition';
  import { impression, trackEvent } from '../../util/snowplow';
  import promos from './Promos';
  import type TrailerPlayer from './TrailerPlayer.svelte';
  import { onMount } from 'svelte';

  const utmParams =
    '?utm_source=deltanrkno&utm_medium=delta&utm_campaign=stjernekamp';

  let Player = $state<typeof TrailerPlayer>();

  onMount(async () => {
    if (promos.some((promo) => promo.videoSrc)) {
      try {
        const module = await import('./TrailerPlayer.svelte');
        Player = module.default;
      } catch (_error) {
        trackEvent({ action: 'error:importplayer' });
      }
    }
  });
</script>

<svelte:head>
  <!-- eslint-disable-next-line svelte/require-each-key -->
  {#each promos as promo}
    <link rel="preload" as="image" href={promo.img} />
  {/each}
</svelte:head>

<article>
  <h2>Oppdag mer av NRK</h2>
  <ul>
    {#each promos as promo (promo.href)}
      <li>
        <div
          class="content-wrapper"
          in:fade={{ delay: 0, duration: 250 }}
          out:fade
        >
          <a
            href={`${promo.href}${utmParams}`}
            onclick={() =>
              trackEvent({ action: 'click:promo', label: promo.contentId })}
            target="_blank"
            referrerpolicy="no-referrer"
            use:impression={promo.contentId}
          >
            {#if promo.videoSrc && Player}
              <Player src={promo.videoSrc} />
            {/if}
            <!-- svelte-ignore a11y_incorrect_aria_attribute_type_boolean -->
            <img
              width="496"
              height="279"
              aria-hidden
              src={promo.img}
              alt={promo.description}
            />
            <div class="pilltext">
              {promo.pillText}
            </div>
            <div class="bottom">
              <h3>{promo.title}</h3>
              {#if promo.description}
                <p>{promo.description}</p>
              {/if}
            </div>
          </a>
        </div>
      </li>
    {/each}
  </ul>
</article>

<style>
  article {
    background: rgba(0, 0, 0, 0.6);
    padding: 1rem 0;
    color: var(--sk-light-pink);
    text-align: center;
    margin: -1rem;
    width: calc(100% + 2rem);
  }

  h2 {
    margin: 1rem 0;
    text-transform: unset;
    color: var(--sk-lighter-blue);
    font-style: normal;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  li {
    display: grid;
  }
  .content-wrapper {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  li {
    max-width: 30rem;
    position: relative;
    margin: 1rem 0.5rem;
    border-radius: 3px;
    overflow: hidden;
    background-color: var(--sk-dark-blue);
    color: white;
  }

  a {
    box-shadow: none;
    text-align: left;
  }

  .bottom {
    padding: 1rem;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
  }

  img {
    height: auto;
    width: 100%;
  }

  .pilltext {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1;
    width: fit-content;
    border-radius: 4px;
    font-weight: 600;
    font-size: 0.8rem;
    color: #f8c4f9;
    padding: 0.2rem 0.4rem;
    background: var(--sk-dark-blue);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 0.2rem;
  }
</style>
