import { get } from 'svelte/store';
import { v4 } from 'uuid';
import { loginModelStore } from '../login/loginModel';
import { getSessionId } from '../util/sessionId';

type FeedbackV1 = {
  comment?: string;
  votesFeedback?: number;
  email?: string;
  category?: string;
};

type FeedbackV2 = {
  appId: string;
  feedbackId: string;
  nrkSessionId: string;
  appVersion: string;
  comment?: string;
  emojiScore?: number;
  jsonData?: Record<string, any>;
};

const initialPayload: FeedbackV2 = {
  appId: 'stjernekamp',
  feedbackId: v4(),
  nrkSessionId: getSessionId(),
  appVersion: import.meta.env.VITE_FULL_VERSION,
};

let updatedPayload: FeedbackV2 = {
  ...initialPayload,
};

export const postFeedbackV2 = async (
  feedback: FeedbackV1,
  fid: string | null
) => {
  if (fid == null) {
    updatedPayload = { ...initialPayload, feedbackId: v4() };
  }

  try {
    if (feedback.comment != null) {
      updatedPayload.comment = feedback.comment;
    }
    if (feedback.category != null) {
      updatedPayload.jsonData = {
        ...(updatedPayload.jsonData || {}),
        category: feedback.category,
      };
    }
    if (feedback.email) {
      updatedPayload.jsonData = {
        ...(updatedPayload.jsonData || {}),
        email: feedback.email,
      };
    }
    if (feedback.votesFeedback != null) {
      updatedPayload.emojiScore = feedback.votesFeedback;
    }

    const token = await get(loginModelStore)?.getAccessToken?.();
    await fetch(`${import.meta.env.VITE_FEEDBACK_API_V2_URL}/api/feedback`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(updatedPayload),
    });
  } catch {
    // Swallow all errors for now
  }
};
