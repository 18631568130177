<script lang="ts">
  import { nrkUserLoggedin, nrkUserNotloggedin } from '@nrk/core-icons';
  import { nrkLogoNrk } from '@nrk/core-icons/logo';
  import { loginModelStore } from './loginModel';
</script>

<header>
  <a href="https://www.nrk.no" aria-label="NRK">
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkLogoNrk}
  </a>
  {#if $loginModelStore?.session == undefined}
    <span>Laster..</span>
  {:else if $loginModelStore?.session.type === 'unauthenticated'}
    <button
      class="nrk-user"
      on:click={() => {
        $loginModelStore.login();
      }}
      aria-label="Logg på"
    >
      <!-- eslint-disable svelte/no-at-html-tags -->
      {@html nrkUserNotloggedin}
      <span> Logg på </span>
    </button>
  {:else}
    <button
      class="nrk-user"
      on:click={() => {
        $loginModelStore.gotoUserPage();
      }}
      aria-label="Til brukerside for {$loginModelStore.session.name}"
    >
      <!-- eslint-disable svelte/no-at-html-tags -->
      {@html nrkUserLoggedin}
      <span> {$loginModelStore.session.name} </span>
    </button>
  {/if}
</header>
{#if $loginModelStore?.loginTestUser != null}
  {#await import ('./loginDialog/LocalLoginDialog.svelte') then LoginDialog}
    <svelte:component this={LoginDialog.default} />
  {/await}
{/if}

<style>
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;
  }

  a {
    display: block;
    padding: 1.25rem;
    font-size: 1.6rem;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .nrk-user {
    padding: 1.25rem;
    display: inline-flex;
    align-items: center;
    text-transform: unset;
    font-weight: 350;
    font-size: 0.9375rem;
    line-height: 1.5;
  }

  .nrk-user:hover {
    text-decoration: underline;
  }

  .nrk-user span {
    margin-left: 0.5rem;
  }
</style>
