<script lang="ts">
  import { nrkSpinner } from '@nrk/core-icons';
  import { onMount } from 'svelte';

  export let description: string;

  let displaySlowLoadingText = false;

  onMount(() => {
    const timeoutRef = setTimeout(() => {
      displaySlowLoadingText = true;
    }, 5000);

    return () => {
      clearTimeout(timeoutRef);
    };
  });
</script>

<div class="loader">
  {description}
  {displaySlowLoadingText ? ', dette tok lenger tid enn forventet' : ''}
  <!-- eslint-disable-next-line svelte/no-at-html-tags -->
  <span class="inline-spinner">{@html nrkSpinner}</span>
</div>

<style>
  .loader {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
</style>
