<script lang="ts">
  import StjernekampConfetti from './Stjernekampconfetti/StjernekampConfetti.svelte';
  import { fromEvent } from 'rxjs';

  let confettiElement: StjernekampConfetti;
  export const fireConfetti = (e: CustomEvent) => {
    confettiElement.fireConfetti(e.detail['numberOfStars']);
  };
  const elementType = StjernekampConfetti;

  const confettiEvent = fromEvent<CustomEvent>(document, 'confetti');
  confettiEvent.subscribe(fireConfetti);
</script>

<svelte:component this={elementType} bind:this={confettiElement} />
