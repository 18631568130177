<script lang="ts">
  import { format } from 'date-fns';
  import { nb } from 'date-fns/locale';
  import CoreDialog from './core/CoreDialog.svelte';
  import { trackEvent } from '../util/snowplow';
  import { showEndTime, showTime } from './showtime';

  interface Props {
    endTime: string;
    startTime: string;
  }

  let { endTime, startTime }: Props = $props();

  let closeTime = $derived(
    format(new Date(endTime), "EEEE 'kl.' HH:mm", {
      locale: nb,
    })
  );
  let openTime = $derived(
    format(new Date(startTime), "EEEE 'kl.' HH:mm", {
      locale: nb,
    })
  );
</script>

<button
  class="secondary toggle"
  data-for="could-not-vote"
  onclick={() => {
    trackEvent({ action: 'click:could-not-vote-open' });
  }}
  ><span class="button-text-gradient" aria-hidden="true"
    >Fikk du ikke stemt?</span
  >Fikk du ikke stemt?</button
>
<CoreDialog id="could-not-vote" label="Fikk du ikke stemt?">
  <div class="content">
    <!-- svelte-ignore a11y_autofocus -->
    <h2 autofocus tabindex="-1">Fikk du ikke stemt?</h2>
    <p>Stemmevinduet åpnet {openTime} og stengte {closeTime}</p>
    <p>
      Sendingen gikk fra ca. {showTime}–{showEndTime}.
    </p>
    <p>Husk at du må se sendingen direkte for å få med deg stemmevinduet.</p>
    <button class="secondary close" data-for="close">Lukk</button>
  </div>
</CoreDialog>

<style>
  .toggle {
    margin: 1rem 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: var(--spacing-16) auto;
    padding: var(--spacing-16);
    max-width: 28rem;
  }

  h2:focus {
    box-shadow: none;
  }

  p {
    margin-top: 1rem;
  }

  .close {
    margin-top: 1rem;
  }
</style>
